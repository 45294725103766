import './App.css';
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Tabs, Tab, Typography, Button, Menu, MenuItem, CircularProgress, Tooltip, IconButton, Paper } from '@mui/material';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from './config';
import Map from './components/Map';
import CourtAvailabilityView from './components/CourtAvailabilityView';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useLocation, useNavigate, Routes, Route, Navigate } from 'react-router-dom';
import logo from './logo_transparent.png';
import { getAnalytics, logEvent } from "firebase/analytics";
import LoginPage from './components/LoginPage';
import 'firebase/compat/auth';
import styled from '@emotion/styled';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import LoginIcon from '@mui/icons-material/Login';
import ProfilePage from './components/ProfilePage';
import AdminPage from './components/AdminPage';
import { firebaseConfig } from './firebase/config';

const isDevelopment = process.env.NODE_ENV === 'development';

let app;

if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}
let analytics = getAnalytics(app);

if (!isDevelopment) {
  analytics = getAnalytics(app);
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const StyledTabs = styled(Tabs)({
  '& .MuiTab-root': {
    color: '#fff',
    opacity: 0.7,
    '&.Mui-selected': {
      color: '#fff',
      opacity: 1
    }
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#fff'
  }
});

const formatRelativeTime = (timestamp, isFrench) => {
  if (!timestamp) return '';
  
  const now = new Date();
  const date = new Date(timestamp);
  const diffInSeconds = Math.floor((now - date) / 1000);
  
  if (diffInSeconds < 60) {
    return isFrench ? 
      `Il y a ${diffInSeconds} seconde${diffInSeconds > 1 ? 's' : ''}` :
      `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
  }
  
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return isFrench ?
      `Il y a ${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}` :
      `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  }
  
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return isFrench ?
      `Il y a ${diffInHours} heure${diffInHours > 1 ? 's' : ''}` :
      `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  }
  
  const diffInDays = Math.floor(diffInHours / 24);
  return isFrench ?
    `Il y a ${diffInDays} jour${diffInDays > 1 ? 's' : ''}` :
    `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
};

const translations = {
    en: {
        autoRefreshPremium: "Auto-refresh is a premium feature",
        autoRefreshUpgrade: "Upgrade to Standard plan to enable automatic refresh of availabilities"
    },
    fr: {
        autoRefreshPremium: "L'actualisation automatique est une fonctionnalité premium",
        autoRefreshUpgrade: "Passez au forfait Standard pour activer l'actualisation automatique des disponibilités"
    }
};

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const [selectedTab, setSelectedTab] = useState(query.get('tab') || 'courtAvailability');
  const [searchField, setSearchField] = useState(query.get('search') || '');
  const [lastRefreshTime, setLastRefreshTime] = useState('');
  const [refreshTimeDisplay, setRefreshTimeDisplay] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isFrench, setIsFrench] = useState(true); // Toggle state for language swap
  const [isLoading, setIsLoading] = useState(true);

  // for re-rendering
  const [courtViewKey, setCourtViewKey] = useState(0);



  const [auth, setAuth] = useState(false); // set to true when user is authenticated
  const [userName, setUserName] = useState(''); // set this to the authenticated user's name
  const [anchorPosition, setAnchorPosition] = useState(null);

  const [authChecked, setAuthChecked] = useState(false);

  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(false);

  const [showAutoRefreshPopup, setShowAutoRefreshPopup] = useState(null);
  const autoRefreshButtonRef = useRef(null);

  const [accountType, setAccountType] = useState('free');
  const [showUpgradePopup, setShowUpgradePopup] = useState(null);

  const handleResize = () => {
    const isMobile = window.innerWidth < 600;
    setIsMobile(isMobile);
  };

  const handleLanguageToggle = () => {
      logEvent(analytics, 'language_change', {
        isFrench: !isFrench,
      });
      const newLanguagePreference = !isFrench;
      setIsFrench(newLanguagePreference);
      
      const user = firebase.auth().currentUser;
      if (user) {
        const docRef = firebase.firestore().collection('userPreferences').doc(user.uid);
        docRef.set({
          preferredLanguage: newLanguagePreference ? 'FR' : 'EN'
        }, { merge: true });
      }
      
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top when language is toggled
  };

  useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          if (user) {
              setAuth(true);
              setUserName(user.displayName || 'User');
              
              // Fetch the user's preferred language from Firestore
              const docRef = firebase.firestore().collection('userPreferences').doc(user.uid);
              docRef.get().then((doc) => {
                  if (doc.exists) {
                      setIsFrench(doc.data().preferredLanguage === 'FR');
                  }
                  setIsLoading(false);  // Set loading to false once everything is done
                  setAuthChecked(true);
              });
          } else {
              setAuthChecked(true);
              setAuth(false);
              setUserName('');
              setIsLoading(false);  // Set loading to false
          }
      });

      return () => unsubscribe();
  }, []);


  const handleMenu = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorPosition({
      left: rect.left + rect.width / 2,
      top: rect.bottom,
    });
  };
  
  const handleClose = () => {
    setAnchorPosition(null);
  };

  const handleLogout = () => {
    firebase.auth().signOut().then(() => {
        // Sign-out successful
        setAuth(false);
        setUserName('');
        setAnchorPosition(null);
        setCourtViewKey(prevKey => prevKey + 1);
        navigate('/');
    }).catch(error => {
        // Handle errors here
        console.error("Error signing out: ", error);
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection('lastRefreshTime')
      .doc('time')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setLastRefreshTime(doc.data().time);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  useEffect(() => {
    console.log(searchField);
  }, [searchField]);

  useEffect(() => {
    console.log(selectedTab);
  }, [selectedTab]);

  const handleTabChange = (event, newTab) => {
    logEvent(analytics, 'tab_change', {
      tab: newTab,
    });
    setSelectedTab(newTab);

    // Navigate to the corresponding route
    switch (newTab) {
      case 'courtAvailability':
        navigate('/');
        break;
      case 'map':
        navigate('/map');
        break;
      case 'profile':
        navigate('/profile');
        break;
      case 'login':
        navigate('/login');
        break;
      case 'admin':
        navigate('/admin');
        break;
      default:
        navigate('/');
    }
  };

  const handleSearchFieldChange = (event) => {
    logEvent(analytics, 'search_change', {
      searchField: event.target.value,
    })
    setSearchField(event.target.value);
  };

  // Memoize the updateLastRefreshTime function
  const updateLastRefreshTime = useCallback(async () => {
    const db = firebase.firestore();
    try {
      const [timeDoc, frequencyDoc] = await Promise.all([
        db.collection('lastRefreshTime').doc('time').get(),
        db.collection('lastRefreshTime').doc('frequency').get()
      ]);

      if (timeDoc.exists) {
        setLastRefreshTime(timeDoc.data().time);
      }
      // Return the frequency in milliseconds (database stores it in seconds)
      return frequencyDoc.exists ? frequencyDoc.data().frequency * 1000 : 5 * 60 * 1000; // Default to 5 minutes
    } catch (error) {
      console.error("Error fetching refresh data:", error);
      return 5 * 60 * 1000; // Default to 5 minutes on error
    }
  }, []); // Empty dependency array since it doesn't depend on any props or state

  const renderMap = useMemo(() => {
    return selectedTab === 'map' ? (
      <Map 
        isFrench={isFrench} 
        analytics={analytics}
        updateLastRefreshTime={updateLastRefreshTime}
        autoRefreshEnabled={autoRefreshEnabled}
        auth={auth}
      />
    ) : null;
  }, [selectedTab, isFrench, updateLastRefreshTime, autoRefreshEnabled, auth]); // Add new dependencies

  // Separate effect for initial fetch
  useEffect(() => {
    updateLastRefreshTime();
  }, [updateLastRefreshTime]);

  // Separate effect for display updates
  useEffect(() => {
    if (lastRefreshTime) {
      const updateDisplay = () => {
        setRefreshTimeDisplay(formatRelativeTime(lastRefreshTime, isFrench));
      };
      
      updateDisplay(); // Initial update
      const timer = setInterval(updateDisplay, 10000);

      return () => clearInterval(timer);
    }
  }, [lastRefreshTime, isFrench]);

  useEffect(() => {
    if (auth) {
      // Get user's account type and preferences
      const user = firebase.auth().currentUser;
      if (user) {
        // Get account type
        firebase.firestore().collection('users').doc(user.uid).onSnapshot((doc) => {
          if (doc.exists) {
            const userType = doc.data().accountType || 'free';
            setAccountType(userType);
            
            // If user is free or downgraded to free, disable auto-refresh in both UI and database
            if (userType === 'free') {
              setAutoRefreshEnabled(false);
              // Update database to ensure auto-refresh is disabled
              firebase.firestore().collection('userPreferences').doc(user.uid).set({
                autoRefreshEnabled: false
              }, { merge: true });
            } else {
              // For non-free users, get their auto-refresh preference
              firebase.firestore().collection('userPreferences').doc(user.uid).get().then((prefDoc) => {
                if (prefDoc.exists) {
                  setAutoRefreshEnabled(prefDoc.data().autoRefreshEnabled || false);
                }
              });
            }
          }
        });
      }
    } else {
      setAccountType('free');
      setAutoRefreshEnabled(false);
    }
  }, [auth]);

  const handleAutoRefreshToggle = () => {
    if (!auth) {
      logEvent(analytics, 'auto_refresh_prompt_login');
      const rect = autoRefreshButtonRef.current.getBoundingClientRect();
      const position = {
        top: rect.top - 10,
        left: rect.left - 160
      };
      setShowAutoRefreshPopup(position);
      return;
    }

    if (accountType === 'free') {
      logEvent(analytics, 'auto_refresh_prompt_upgrade');
      const rect = autoRefreshButtonRef.current.getBoundingClientRect();
      const position = {
        top: rect.top - 10,
        left: rect.left - 160
      };
      setShowUpgradePopup(position);
      return;
    }

    const newAutoRefreshValue = !autoRefreshEnabled;
    setAutoRefreshEnabled(newAutoRefreshValue);
    
    const user = firebase.auth().currentUser;
    if (user) {
      const docRef = firebase.firestore().collection('userPreferences').doc(user.uid);
      docRef.set({
        autoRefreshEnabled: newAutoRefreshValue
      }, { merge: true });
      
      logEvent(analytics, 'auto_refresh_toggle', {
        enabled: newAutoRefreshValue
      });
    }
  };

  const AutoRefreshLoginPopup = ({ position, onClose }) => (
    <Paper style={{
      position: 'fixed', 
      top: position.top - 180,
      left: Math.max(position.left - 160, 10),
      backgroundColor: 'white', 
      padding: '20px', 
      borderRadius: '5px', 
      boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', 
      zIndex: 1000,
      color: 'rgba(0, 0, 0, 0.87)',
      width: '320px',
      maxWidth: 'calc(100vw - 20px)',
      right: position.left + 320 > window.innerWidth ? '10px' : 'auto'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '10px' }}>
        <Typography 
          variant="h6"
          sx={{ 
            fontSize: '1.1rem',
            lineHeight: 1.2,
            flex: 1,
            marginRight: '8px'
          }}
        >
          {isFrench ? "Connexion Requise" : "Login Required"}
        </Typography>
        <IconButton 
          size="small" 
          onClick={onClose}
          sx={{ 
            padding: '4px',
            marginTop: '-4px',
            marginRight: '-4px'
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Typography 
        variant="body1" 
        sx={{ 
          marginBottom: '20px',
          fontSize: '0.95rem',
          lineHeight: 1.3
        }}
      >
        {isFrench ? "Vous avez besoin d'un compte pour activer l'actualisation automatique." : "You need an account to enable auto-refresh."}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button 
          onClick={onClose}
          size="small"
        >
          {isFrench ? "Fermer" : "Close"}
        </Button>
        <Button 
          variant="contained" 
          color="primary"
          size="small"
          onClick={() => {
            setSelectedTab('login');
            onClose();
          }}
        >
          {isFrench ? "Connexion / Inscription" : "Login / Signup"}
        </Button>
      </div>
    </Paper>
  );

  const AutoRefreshUpgradePopup = ({ position, onClose }) => (
    <Paper style={{
      position: 'fixed', 
      top: position.top - 180,
      left: Math.max(position.left - 160, 10),
      backgroundColor: 'white', 
      padding: '20px', 
      borderRadius: '5px', 
      boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', 
      zIndex: 1000,
      color: 'rgba(0, 0, 0, 0.87)',
      width: '320px',
      maxWidth: 'calc(100vw - 20px)',
      right: position.left + 320 > window.innerWidth ? '10px' : 'auto'
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '10px' }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: '1.1rem',
            lineHeight: 1.2,
            flex: 1,
            marginRight: '8px'
          }}
        >
          {isFrench ? translations.fr.autoRefreshPremium : translations.en.autoRefreshPremium}
        </Typography>
        <IconButton 
          size="small" 
          onClick={onClose} 
          sx={{ 
            padding: '4px',
            marginTop: '-4px',
            marginRight: '-4px'
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Typography 
        variant="body1" 
        sx={{ 
          marginBottom: '20px',
          fontSize: '0.95rem',
          lineHeight: 1.3
        }}
      >
        {isFrench ? translations.fr.autoRefreshUpgrade : translations.en.autoRefreshUpgrade}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button 
          onClick={onClose}
          size="small"
        >
          {isFrench ? "Fermer" : "Close"}
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          size="small"
          onClick={() => {
            navigate('/profile');
            onClose();
          }}
        >
          {isFrench ? "Mettre à niveau" : "Upgrade"}
        </Button>
      </div>
    </Paper>
  );

  // Get current route for tab value
  const getCurrentTab = () => {
    const path = location.pathname;
    if (path === '/') return 'courtAvailability';
    if (path === '/map') return 'map';
    if (path === '/profile') return 'profile';
    if (path === '/login') return 'login';
    if (path === '/admin') return 'admin';
    return 'courtAvailability';
  };
  
  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <div className="main">
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        ) : ( <>
          <div style={{ position: 'absolute', top: -26, left: -9, margin: "0px" }}>
            <img src={logo} alt="Logo" style={{ width: '100px', height: '100px' }} />
          </div>
          {authChecked ? (
            auth ? (
            <div className="login-button-container" style={{ marginTop: isMobile ? 1 : '-4px', marginRight: isMobile ? '5px' : '8px' }}>
              <Tooltip title={isFrench ? `Salut, ${userName.split(" ")[0]}!` : `Hi, ${userName.split(" ")[0]}!`}>
                <IconButton
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  size={isMobile ? "small" : "medium"}
                  sx={{ 
                    border: '1px solid rgba(255, 255, 255, 0.5)',
                    padding: isMobile ? '4px' : '8px',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)'
                    }
                  }}
                >
                  <AccountCircleIcon fontSize={isMobile ? "small" : "medium"} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorPosition={anchorPosition}
                anchorReference="anchorPosition"
                keepMounted
                open={Boolean(anchorPosition)}
                onClose={handleClose}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MenuItem onClick={() => {
                  navigate('/profile');
                  handleClose();
                }}>{isFrench ? "Mon Profil" : "My Profile"}</MenuItem>
                <MenuItem onClick={handleLogout}>{isFrench ? "Déconnecter" : "Logout"}</MenuItem>
              </Menu>
            </div>
          ) : 
          <div className="login-button-container" style={{ marginTop: isMobile ? 1 : '-4px', marginRight: isMobile ? '6px' : '8px' }}>
            {isMobile ? (
              <Tooltip title={isFrench ? "Connexion / Inscription" : "Login / Sign up"}>
                <IconButton 
                  color="primary"
                  onClick={() => navigate('/login')}
                  size="small"
                  sx={{ 
                    backgroundColor: 'white',
                    padding: '4px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)'
                    }
                  }}
                >
                  <PersonAddAltIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Button 
                variant='contained' 
                color='primary' 
                size='small' 
                onClick={() => navigate('/login')}
                startIcon={<PersonAddAltIcon />}
              >
                {isFrench ? "Connexion" : "Login"}
              </Button>
            )}
          </div>)
          :   (
            // Placeholder button in a "loading" state
            <div className="login-button-container" style={{ marginTop: isMobile ? 1 : '-4px', marginRight: isMobile ? '6px' : '8px' }}>
              <IconButton 
                color="inherit"
                size={isMobile ? "small" : "medium"}
                disabled
              >
                <CircularProgress size={isMobile ? 16 : 24} color="inherit" />
              </IconButton>
            </div>
          )
        }
          <StyledTabs style={{ height: "5vh" }} value={getCurrentTab()} onChange={handleTabChange} centered>
            <Tab label={isFrench ? "Recherche" : "Court Search"} value="courtAvailability" />
            <Tab label={isFrench ? "Carte" : "Map"} value="map" />
            <Tab sx={{ display: 'none' }} value="profile" />
            <Tab sx={{ display: 'none' }} value="login" />
            <Tab sx={{ display: 'none' }} value="admin" />
          </StyledTabs>
          <Routes>
            <Route path="/" element={
              <CourtAvailabilityView
                key={courtViewKey}
                searchField={searchField}
                onSearchFieldChange={handleSearchFieldChange}
                isFrench={isFrench}
                analytics={analytics}
                setSelectedTab={handleTabChange}
                updateLastRefreshTime={updateLastRefreshTime}
                autoRefreshEnabled={autoRefreshEnabled}
                auth={auth}
              />
            } />
            <Route path="/map" element={
              <Map 
                isFrench={isFrench} 
                analytics={analytics}
                updateLastRefreshTime={updateLastRefreshTime}
                autoRefreshEnabled={autoRefreshEnabled}
                auth={auth}
              />
            } />
            <Route path="/profile" element={
              auth ? (
                <ProfilePage 
                  isFrench={isFrench}
                  analytics={analytics}
                  autoRefreshEnabled={autoRefreshEnabled}
                  setAutoRefreshEnabled={setAutoRefreshEnabled}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            } />
            <Route path="/login" element={
              <LoginPage 
                isFrench={isFrench}
                analytics={analytics}
                setSelectedTab={handleTabChange}
              />
            } />
            <Route path="/admin" element={
              auth ? (
                <AdminPage 
                  isFrench={isFrench}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            } />
          </Routes>
          <div style={{ textAlign: 'center', marginTop: '0.5vh', height: "5vh"}}>
          <div style={{paddingTop:isMobile ? '0.25em' :"0em"}} className={isMobile ? 'last-refresh-time-footer-mobile' : 'last-refresh-time-footer'}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" style={{ fontSize: isMobile ? '12px' : '12px', lineHeight: isMobile ? '1.1' : "inherit"  }}>
                  {isFrench ? "Dernière actualisation" : "Last refresh"}: {refreshTimeDisplay}
                </Typography>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                  <Tooltip 
                    title={auth ? 
                      (accountType === 'free' ?
                        (isFrench ? translations.fr.autoRefreshUpgrade : translations.en.autoRefreshUpgrade) :
                        (isFrench ? 
                          (autoRefreshEnabled ? "Désactiver l'actualisation automatique" : "Activer l'actualisation automatique") : 
                          (autoRefreshEnabled ? "Disable auto-refresh" : "Enable auto-refresh")
                        )
                      ) : 
                      (isFrench ? 
                        "Connectez-vous pour activer l'actualisation automatique des disponibilités" : 
                        "Sign in to enable automatic refresh of availabilities"
                      )
                    }
                    enterDelay={200}
                    leaveDelay={auth ? 200 : 300}
                  >
                    <IconButton 
                      ref={autoRefreshButtonRef}
                      size="small" 
                      onClick={handleAutoRefreshToggle}
                      style={{ 
                        color: accountType === 'free' ? '#ffffff' : (autoRefreshEnabled ? '#64b5f6' : '#ffffff'),
                        opacity: auth && accountType !== 'free' ? 1 : 0.7,
                        padding: '4px'
                      }}
                    >
                      <AutorenewIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {showAutoRefreshPopup && 
                    <AutoRefreshLoginPopup 
                      position={showAutoRefreshPopup} 
                      onClose={() => setShowAutoRefreshPopup(null)} 
                    />
                  }
                  {showUpgradePopup && 
                    <AutoRefreshUpgradePopup 
                      position={showUpgradePopup} 
                      onClose={() => setShowUpgradePopup(null)} 
                    />
                  }
                </div>
              </div>
            </div>
          </div>

            <p style={{ paddingTop:'0em', paddingBottom: '1em', margin: '0', fontSize: "medium", backgroundColor: "#282c34"  }}>
              {isFrench ? (
                <>
                  Réalisé avec ❤️ et&nbsp;
                  <a href="https://www.buymeacoffee.com/zilongl" onClick={()=>{logEvent(analytics, 'donation_link_click')}} target='_blank' style={{ color: '#64b5f6', textDecoration: "none" }}>
                    ☕(soutenez ma dépendance à la caféine!)
                  </a> 
                  &nbsp;à Montréal&nbsp;=&gt;
                  <span variant="contained" style={{  height: "inherit", color: '#64b5f6', cursor: 'pointer'}} color="primary" onClick={handleLanguageToggle}>
                    &nbsp;({isFrench ? "English please!" : "En Français S.V.P.!"})
                  </span>
                </>
              ) : (
                <>
                  Made with ❤️ and&nbsp;
                  <a href="https://www.buymeacoffee.com/zilongl" onClick={()=>{logEvent(analytics, 'donation_link_click')}} target='_blank' style={{ color: '#64b5f6', textDecoration: "none" }}>
                    ☕(support my caffeine addiction!)
                  </a> 
                  &nbsp;in Montreal&nbsp;=&gt;
                  <span variant="contained" style={{  height: "inherit", color: '#64b5f6', cursor: 'pointer'}} color="primary" onClick={handleLanguageToggle}>
                    &nbsp;({isFrench ? "English please!" : "En Français S.V.P.!"})
                  </span>
                </>
              )}
              </p>
          </div>
        </>)}
      </div>
    </LoadScript>
  );
};

export default App;
