import React, { useState, useEffect } from 'react';
import {
    Container,
    Paper,
    Typography,
    Switch,
    FormControlLabel,
    Divider,
    Box,
    Chip,
    Avatar,
    CircularProgress,
    TextField,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";
import SubscriptionModal from './SubscriptionModal';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CircleIcon from '@mui/icons-material/Circle';
import { OpenInNew as OpenInNewIcon, CallMade as CallMadeIcon, Menu as MenuIcon } from '@mui/icons-material';
import { STRIPE_CLOUD_FUNCTION_BASE_URL } from '../config';
const translations = {
    en: {
        profile: "Profile",
        personalInfo: "Personal Information",
        personalInfoMobile: "Infos Perso",
        name: "Name",
        email: "Email",
        settings: "Settings",
        autoRefresh: "Auto-refresh",
        autoRefreshMobile: "Auto-refresh",
        subscription: "Subscription",
        subscriptionMobile: "Plan",
        accountType: "Account Type",
        accountTypeMobile: "Plan Type",
        phone: "Phone Number",
        phoneMobile: "Phone",
        phoneOptional: "Phone Number (Optional)",
        phoneOptionalMobile: "Phone (Optional)",
        phoneHelp: "Used for notifications",
        phoneHelpMobile: "For notifications",
        free: "Free",
        standard: "Premium",
        pro: "Pro",
        loading: "Loading...",
        edit: "Edit",
        save: "Save",
        cancel: "Cancel",
        editSuccess: "Profile updated successfully",
        editError: "Error updating profile",
        markerIcon: "Map Marker Icon",
        markerIconMobile: "Marker",
        markerDefault: "Default",
        markerTennisRacquet: "Tennis Racquet",
        markerTennisBall: "Tennis Ball",
        markerStar: "Star",
        markerHeart: "Heart",
        markerDot: "Dot",
        bookingBehavior: "Booking Button Preference",
        bookingBehaviorMobile: "Book Button",
        bookingPopup: "Show All Options",
        bookingDirect: "Quick Book",
        bookingSearch: "Search Results",
        subscriptionDetails: "Subscription Details",
        subscriptionStatus: "Status",
        subscriptionPeriodEnd: "Renewal Date",
        subscriptionActive: "Active",
        subscriptionCanceled: "Canceled",
        renewsOn: "Renews on",
        endsOn: "Ends on",
        manageSubscription: "Manage Subscription",
        upgrade: "Upgrade",
        standardGranted: "Premium (VIP)",
        expiresOn: "VIP access until",
        upgradeForFeature: "Upgrade to Premium plan to customize these features",
        upgradeButton: "Upgrade to Premium"
    },
    fr: {
        profile: "Profil",
        personalInfo: "Informations Personnelles",
        personalInfoMobile: "Infos Perso",
        name: "Nom",
        email: "Courriel",
        settings: "Paramètres",
        autoRefresh: "Actualisation automatique",
        autoRefreshMobile: "Auto-actualiser",
        subscription: "Abonnement",
        subscriptionMobile: "Forfait",
        accountType: "Type de compte",
        accountTypeMobile: "Type",
        phone: "Téléphone",
        phoneMobile: "Tél",
        phoneOptional: "Téléphone (Optionnel)",
        phoneOptionalMobile: "Tél (Opt)",
        phoneHelp: "Utilisé pour les notifications",
        phoneHelpMobile: "Pour notifications",
        free: "Gratuit",
        standard: "Premium",
        pro: "Pro",
        loading: "Chargement...",
        edit: "Modifier",
        save: "Enregistrer",
        cancel: "Annuler",
        editSuccess: "Profil mis à jour avec succès",
        editError: "Erreur lors de la mise à jour du profil",
        markerIcon: "Icône du marqueur",
        markerIconMobile: "Marqueur",
        markerDefault: "Défaut",
        markerTennisRacquet: "Raquette",
        markerTennisBall: "Balle",
        markerStar: "Étoile",
        markerHeart: "Cœur",
        markerDot: "Point",
        bookingBehavior: "Préférence du bouton de réservation",
        bookingBehaviorMobile: "Bouton Rés.",
        bookingPopup: "Afficher toutes les options",
        bookingDirect: "Réservation rapide",
        bookingSearch: "Résultats de recherche",
        subscriptionDetails: "Détails de l'abonnement",
        subscriptionStatus: "Statut",
        subscriptionPeriodEnd: "Date de renouvellement",
        subscriptionActive: "Actif",
        subscriptionCanceled: "Annulé",
        renewsOn: "Se renouvelle le",
        endsOn: "Se termine le",
        manageSubscription: "Gérer l'abonnement",
        upgrade: "Mettre à niveau",
        standardGranted: "Premium (VIP)",
        expiresOn: "Accès VIP jusqu'au",
        upgradeForFeature: "Passez au forfait Premium pour personnaliser ces fonctionnalités",
        upgradeButton: "Passer au Premium"
    }
};

const StyledContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#282c34',
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: theme.spacing(4),
    backgroundImage: 'radial-gradient(ellipse at 50% 50%, #353b47, #282c34)',
    [theme.breakpoints.up('sm')]: {
        minHeight: '86vh',
        paddingTop: theme.spacing(6)
    }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    maxWidth: '600px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    }
}));

const InfoSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const InfoRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(1),
        '&.keep-row': {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        }
    }
}));

const formatPhoneNumber = (value) => {
    if (!value) return value;
    
    // Remove all non-numeric characters
    const phoneNumber = value.replace(/[^\d]/g, '');
    
    // Format the number as (XXX) XXX-XXXX
    if (phoneNumber.length <= 3) {
        return phoneNumber;
    }
    if (phoneNumber.length <= 6) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const markerIcons = {
    default: {
        icon: <LocationOnIcon sx={{ color: '#EA4335' }} />,
        value: 'default'
    },
    tennisRacquet: {
        icon: <SportsTennisIcon sx={{ color: '#0074D9' }} />,
        value: 'tennisRacquet'
    },
    tennisBall: {
        icon: <SportsBaseballIcon sx={{ color: '#B8860B' }} />,
        value: 'tennisBall'
    },
    star: {
        icon: <StarIcon sx={{ color: '#32CD32' }} />,
        value: 'star'
    },
    heart: {
        icon: <FavoriteIcon sx={{ color: '#960A0A' }} />,
        value: 'heart'
    },
    dot: {
        icon: <CircleIcon sx={{ color: '#800080' }} />,
        value: 'dot'
    }
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
  '& .MuiToggleButton-root': {
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2),
    textTransform: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px !important',
    '&.Mui-selected': {
      backgroundColor: '#e8f5e9',
      color: 'green',
      borderColor: 'green',
      '&:hover': {
        backgroundColor: '#c8e6c9'
      }
    },
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  }
}));

const BookingOptionContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  gap: '12px'
}));

const BookingOptionText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 1,
  gap: '4px'
}));

const BookingOptionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.95rem',
  color: 'inherit',
  lineHeight: 1.2,
  textAlign: 'left'
}));

const BookingOptionDescription = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  color: 'rgba(0, 0, 0, 0.6)',
  lineHeight: 1.2,
  textAlign: 'left',
  width: '100%'
}));

// Update the SettingSection styling
const SettingSection = styled(Box)(({ theme, disabled }) => ({
    marginBottom: theme.spacing(5),
    backgroundColor: '#f8f9fa',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    position: 'relative',
    '&:last-child': {
        marginBottom: 0
    },
    ...(disabled && {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(250, 250, 250, 0.7)',
            backdropFilter: 'blur(0.2px)',
            borderRadius: 'inherit',
            zIndex: 1
        },
        '& > *': {
            opacity: 0.9,
        }
    })
}));

const SettingTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.1rem',
  color: 'rgba(0, 0, 0, 0.87)',
  marginBottom: theme.spacing(2)
}));

export default function ProfilePage({ isFrench, analytics, autoRefreshEnabled, setAutoRefreshEnabled }) {
    const [user, setUser] = useState(null);
    const [userDoc, setUserDoc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [editedData, setEditedData] = useState({
        displayName: '',
        email: '',
        phone: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [accountType, setAccountType] = useState('free');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState('default');
    const [selectedBookingBehavior, setSelectedBookingBehavior] = useState('popup');
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [subscriptionPeriodEnd, setSubscriptionPeriodEnd] = useState(null);
    const [subscriptionCancelAtPeriodEnd, setSubscriptionCancelAtPeriodEnd] = useState(false);
    const [loadingManage, setLoadingManage] = useState(false);

    const t = isFrench ? translations.fr : translations.en;

    useEffect(() => {
        const unsubscribeAuth = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            if (firebaseUser) {
                setUser(firebaseUser);
                // Set up real-time listener for user's Firestore document
                const unsubscribeFirestore = firebase.firestore()
                    .collection('users')
                    .doc(firebaseUser.uid)
                    .onSnapshot((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            setUserDoc(data);
                            setAccountType(data.accountType || 'free');
                            setSubscriptionStatus(data.subscriptionStatus);
                            setSubscriptionPeriodEnd(data.subscriptionPeriodEnd?.toDate());
                            setSubscriptionCancelAtPeriodEnd(data.subscriptionCancelAtPeriodEnd || false);
                            setEditedData({
                                displayName: data.displayName || '',
                                email: data.email || '',
                                phone: data.phone || '',
                            });
                            setSelectedMarker(data.selectedMarker || 'default');
                            setSelectedBookingBehavior(data.bookingBehavior || 'popup');
                        }
                        setLoading(false);
                    });
                return () => unsubscribeFirestore();
            } else {
                setUser(null);
                setUserDoc(null);
                setLoading(false);
            }
        });

        return () => unsubscribeAuth();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Load user preferences
        const user = firebase.auth().currentUser;
        if (user) {
            firebase.firestore().collection('userPreferences').doc(user.uid).get()
                .then((doc) => {
                    if (doc.exists) {
                        const data = doc.data();
                        setSelectedMarker(data.markerIcon || 'default');
                        setAutoRefreshEnabled(data.autoRefreshEnabled || false);
                        setSelectedBookingBehavior(data.bookingBehavior || 'popup');
                    }
                });
        }
    }, []);

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (!user) return;

        // Set up real-time listener for subscription changes
        const unsubscribe = firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    const userData = doc.data();
                    setAccountType(userData.accountType || 'free');
                    setSubscriptionStatus(userData.subscriptionStatus);
                    setSubscriptionPeriodEnd(userData.subscriptionPeriodEnd?.toDate());
                    setSubscriptionCancelAtPeriodEnd(userData.subscriptionCancelAtPeriodEnd || false);
                }
            });

        return () => unsubscribe();
    }, []);

    const handleEditClick = () => {
        setEditing(true);
        setError('');
        setSuccess('');
    };

    const handleCancelEdit = () => {
        setEditing(false);
        setEditedData({
            displayName: user?.displayName || '',
            email: user?.email || '',
            phone: user?.phone || '',
        });
        setError('');
        setSuccess('');
    };

    const handleSaveEdit = async () => {
        try {
            const currentUser = firebase.auth().currentUser;
            if (!currentUser) return;

            // Update Firebase Auth profile
            await currentUser.updateProfile({
                displayName: editedData.displayName,
            });

            // Update Firestore document
            await firebase.firestore().collection('users').doc(currentUser.uid).update({
                displayName: editedData.displayName,
                phone: editedData.phone,
                photoURL: currentUser.photoURL,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            });

            setUser({ 
                ...currentUser, 
                displayName: editedData.displayName,
                phone: editedData.phone,
                photoURL: currentUser.photoURL,
            });
            setEditing(false);
            setSuccess(t.editSuccess);
            
            // Log the event
            logEvent(analytics, 'profile_updated');
        } catch (err) {
            console.error('Error updating profile:', err);
            setError(t.editError);
        }
    };

    const handleAutoRefreshChange = (event) => {
        const newValue = event.target.checked;
        setAutoRefreshEnabled(newValue);
        
        const user = firebase.auth().currentUser;
        if (user) {
            const docRef = firebase.firestore().collection('userPreferences').doc(user.uid);
            docRef.set({
                autoRefreshEnabled: newValue
            }, { merge: true });
        }
    };

    const handlePhoneChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        // Only update if the phone number is valid or empty
        if (!formattedPhone || formattedPhone.length <= 14) {
            setEditedData({ ...editedData, phone: formattedPhone });
        }
    };

    const handleMarkerChange = (event, newValue) => {
        if (newValue !== null) {
            setSelectedMarker(newValue);
            const user = firebase.auth().currentUser;
            if (user) {
                firebase.firestore().collection('userPreferences').doc(user.uid)
                    .set({
                        markerIcon: newValue
                    }, { merge: true });
                
                logEvent(analytics, 'marker_icon_changed', {
                    marker_type: newValue
                });
            }
        }
    };

    const handleBookingBehaviorChange = (event) => {
        const newValue = event.target.value;
        setSelectedBookingBehavior(newValue);
        
        const user = firebase.auth().currentUser;
        if (user) {
            firebase.firestore().collection('userPreferences').doc(user.uid)
                .set({
                    bookingBehavior: newValue
                }, { merge: true });
            
            logEvent(analytics, 'booking_behavior_changed', {
                behavior: newValue
            });
        }
    };

    const handleManageSubscription = async () => {
        setLoadingManage(true);
        setError('');

        try {
            const user = firebase.auth().currentUser;
            if (!user) {
                throw new Error('Please sign in to manage your subscription');
            }

            const idToken = await user.getIdToken();
            const response = await fetch(`${STRIPE_CLOUD_FUNCTION_BASE_URL}/create-portal-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    locale: isFrench ? 'fr' : 'en'
                })
            });

            console.log('Portal session response status:', response.status);
            const responseText = await response.text();
            console.log('Portal session response text:', responseText);

            if (!response.ok) {
                throw new Error(responseText || 'Failed to access subscription management');
            }

            let data;
            try {
                data = JSON.parse(responseText);
            } catch (e) {
                console.error('Failed to parse response:', e);
                throw new Error('Invalid response from server');
            }

            if (!data.url) {
                throw new Error('No portal URL in response');
            }

            window.location.href = data.url;
        } catch (err) {
            console.error('Portal session error:', err);
            setError(err.message || 'Failed to access subscription management');
        } finally {
            setLoadingManage(false);
        }
    };

    if (loading) {
        return (
            <StyledContainer>
                <CircularProgress />
            </StyledContainer>
        );
    }

    const getAccountTypeColor = (type) => {
        switch (type) {
            case 'pro':
                return 'secondary';
            case 'standard':
                return 'primary';
            default:
                return 'default';
        }
    };

    return (
        <StyledContainer>
            <StyledPaper>
                <Box display="flex" alignItems="center" mb={3}>
                    <Avatar 
                        src={user?.photoURL}
                        sx={{ width: 56, height: 56, marginRight: 2 }}
                    >
                        {userDoc?.displayName?.charAt(0) || userDoc?.email?.charAt(0)}
                    </Avatar>
                    <Typography variant="h5" component="h1">
                        {t.profile}
                    </Typography>
                </Box>

                {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
                {success && <Typography color="success.main" sx={{ mb: 2 }}>{success}</Typography>}

                <InfoSection>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h6">
                            {isMobile ? t.personalInfoMobile : t.personalInfo}
                        </Typography>
                        {!editing ? (
                            <Button size="small" onClick={handleEditClick}>{t.edit}</Button>
                        ) : (
                            <Box>
                                <Button size="small" onClick={handleCancelEdit} sx={{ mr: 1 }}>{t.cancel}</Button>
                                <Button size="small" variant="contained" onClick={handleSaveEdit}>{t.save}</Button>
                            </Box>
                        )}
                    </Box>
                    <InfoRow>
                        <Typography variant="body1" color="textSecondary">
                            {t.name}
                        </Typography>
                        {editing ? (
                            <TextField
                                size="small"
                                value={editedData.displayName}
                                onChange={(e) => setEditedData({ ...editedData, displayName: e.target.value })}
                            />
                        ) : (
                            <Typography variant="body1">
                                {user?.displayName || '-'}
                            </Typography>
                        )}
                    </InfoRow>
                    <InfoRow>
                        <Typography variant="body1" color="textSecondary">
                            {t.email}
                        </Typography>
                        <Typography variant="body1">
                            {editedData.email}
                        </Typography>
                    </InfoRow>
                    <InfoRow>
                        <Typography variant="body1" color="textSecondary">
                            {isMobile ? t.phoneMobile : t.phone}
                        </Typography>
                        {editing ? (
                            <TextField
                                size="small"
                                value={editedData.phone}
                                onChange={handlePhoneChange}
                                placeholder={isMobile ? t.phoneOptionalMobile : t.phoneOptional}
                                helperText={isMobile ? t.phoneHelpMobile : t.phoneHelp}
                                inputProps={{
                                    maxLength: 14 // "(XXX) XXX-XXXX".length
                                }}
                            />
                        ) : (
                            <Typography variant="body1">
                                {editedData.phone || '-'}
                            </Typography>
                        )}
                    </InfoRow>
                </InfoSection>

                <Divider sx={{ my: 3 }} />

                <InfoSection>
                    <Typography variant="h6" gutterBottom>
                        {isMobile ? t.subscriptionMobile : t.subscription}
                    </Typography>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        gap: 2,
                        backgroundColor: 'background.default',
                        borderRadius: 1,
                        p: 2.5
                    }}>
                        {/* Plan Type Row */}
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => setSubscriptionModalOpen(true)}
                        >
                            <Typography variant="body1" color="textSecondary">
                                {isMobile ? t.accountTypeMobile : t.accountType}
                            </Typography>
                            <Chip
                                label={accountType === 'standard' && userDoc?.isAdminGranted ? t.standardGranted : t[accountType]}
                                color={getAccountTypeColor(accountType)}
                                variant={accountType === 'free' ? 'outlined' : 'filled'}
                                size={isMobile ? "small" : "medium"} 
                                sx={{ 
                                    '&:hover': {
                                        opacity: 0.8,
                                        transform: 'scale(1.02)',
                                        transition: 'all 0.2s ease'
                                    }
                                }}
                            />
                        </Box>

                        {/* Subscription Details */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                            {/* Status Row - Only show for paid plans */}
                            {accountType !== 'free' && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        {t.subscriptionStatus}
                                    </Typography>
                                    <Chip
                                        label={subscriptionCancelAtPeriodEnd ? t.subscriptionCanceled : t.subscriptionActive}
                                        color={subscriptionCancelAtPeriodEnd ? "error" : "success"}
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                            borderWidth: '2px',
                                            fontWeight: 500
                                        }}
                                    />
                                </Box>
                            )}

                            {/* Period End Row - Only show for paid plans */}
                            {accountType !== 'free' && (userDoc?.subscriptionPeriodEnd || userDoc?.subscriptionEndDate) && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        {userDoc?.isAdminGranted ? t.expiresOn : (subscriptionCancelAtPeriodEnd ? t.endsOn : t.renewsOn)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                        {(userDoc.subscriptionPeriodEnd?.toDate() || userDoc.subscriptionEndDate?.toDate())?.toLocaleDateString(isFrench ? 'fr-FR' : 'en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric'
                                        })}
                                    </Typography>
                                </Box>
                            )}

                            {/* Action Button - Hide for admin-granted plans */}
                            {accountType !== 'free' && !userDoc?.isAdminGranted && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleManageSubscription}
                                        startIcon={loadingManage ? <CircularProgress size={20} color="inherit" /> : <OpenInNewIcon />}
                                        disabled={loadingManage}
                                        color={subscriptionCancelAtPeriodEnd ? "primary" : "inherit"}
                                        sx={{ 
                                            minWidth: '200px',
                                            textTransform: 'none',
                                            fontWeight: 500,
                                            boxShadow: subscriptionCancelAtPeriodEnd ? 2 : 0,
                                            '&:hover': {
                                                boxShadow: subscriptionCancelAtPeriodEnd ? 4 : 1
                                            }
                                        }}
                                    >
                                        {t.manageSubscription}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </InfoSection>

                <Divider sx={{ my: 3 }} />

                <InfoSection>
                    <Typography variant="h6" sx={{ mb: 4, fontWeight: 600 }}>
                        {t.settings}
                    </Typography>

                    {accountType === 'free' && (
                        <Box sx={{ 
                            mb: 4, 
                            p: 2.5, 
                            backgroundColor: 'primary.50',
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1.5
                        }}>
                            <Typography variant="body1" color="primary" sx={{ fontWeight: 500, textAlign: 'center' }}>
                                {t.upgradeForFeature}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setSubscriptionModalOpen(true)}
                                startIcon={<OpenInNewIcon />}
                                sx={{ 
                                    minWidth: '200px',
                                    textTransform: 'none',
                                    fontWeight: 500
                                }}
                            >
                                {t.upgradeButton}
                            </Button>
                        </Box>
                    )}

                    <SettingSection disabled={accountType === 'free'}>
                        <SettingTitle variant="subtitle1">
                            {isMobile ? t.bookingBehaviorMobile : t.bookingBehavior}
                        </SettingTitle>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                            {isFrench 
                                ? "Choisissez comment le bouton de réservation se comportera" 
                                : "Choose how the booking button will behave"}
                        </Typography>
                        <StyledToggleButtonGroup
                            value={accountType === 'free' ? 'popup' : selectedBookingBehavior}
                            exclusive
                            onChange={(e, newValue) => {
                                if (accountType !== 'free' && newValue !== null) {
                                    handleBookingBehaviorChange({ target: { value: newValue } });
                                }
                            }}
                            aria-label="booking behavior selection"
                            disabled={accountType === 'free'}
                        >
                            <ToggleButton value="direct">
                                <BookingOptionContent>
                                    <BookingOptionText>
                                        <BookingOptionTitle>
                                            {t.bookingDirect}
                                        </BookingOptionTitle>
                                        <BookingOptionDescription>
                                            {isFrench 
                                                ? "Aller directement à la page de réservation et ajouter au calendrier" 
                                                : "Go straight to booking page and add to calendar"}
                                        </BookingOptionDescription>
                                    </BookingOptionText>
                                    <OpenInNewIcon sx={{ color: 'inherit', opacity: 0.7 }} />
                                </BookingOptionContent>
                            </ToggleButton>
                            <ToggleButton value="search">
                                <BookingOptionContent>
                                    <BookingOptionText>
                                        <BookingOptionTitle>
                                            {t.bookingSearch}
                                        </BookingOptionTitle>
                                        <BookingOptionDescription>
                                            {isFrench 
                                                ? "Aller directement aux résultats de recherche et ajouter au calendrier" 
                                                : "Go straight to search results and add to calendar"}
                                        </BookingOptionDescription>
                                    </BookingOptionText>
                                    <CallMadeIcon sx={{ color: 'inherit', opacity: 0.7 }} />
                                </BookingOptionContent>
                            </ToggleButton>
                            <ToggleButton value="popup">
                                <BookingOptionContent>
                                    <BookingOptionText>
                                        <BookingOptionTitle>
                                            {t.bookingPopup}
                                        </BookingOptionTitle>
                                        <BookingOptionDescription>
                                            {isFrench 
                                                ? "Choisir entre réservation rapide et résultats de recherche" 
                                                : "Choose between quick book and search results"}
                                        </BookingOptionDescription>
                                    </BookingOptionText>
                                    <MenuIcon sx={{ color: 'inherit', opacity: 0.7 }} />
                                </BookingOptionContent>
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    </SettingSection>

                    <SettingSection disabled={accountType === 'free'}>
                        <SettingTitle variant="subtitle1">
                            {isMobile ? t.markerIconMobile : t.markerIcon}
                        </SettingTitle>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                            {isFrench 
                                ? "Choisissez l'icône qui représentera vos terrains sur la carte" 
                                : "Choose the icon that will represent your courts on the map"}
                        </Typography>
                        <ToggleButtonGroup
                            value={accountType === 'free' ? 'default' : selectedMarker}
                            exclusive
                            onChange={handleMarkerChange}
                            aria-label="marker icon selection"
                            disabled={accountType === 'free'}
                            sx={{
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                display: 'grid',
                                gridTemplateColumns: {
                                    xs: 'repeat(3, 1fr)',
                                    sm: 'repeat(6, 1fr)'
                                },
                                gap: 1,
                                width: '100%',
                                maxWidth: {
                                    xs: '240px',
                                    sm: '100%'
                                },
                                margin: '0 auto',
                                '& .MuiToggleButton-root': {
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px !important',
                                    m: 0.5,
                                    flex: {
                                        xs: '0 0 calc(33.33% - 16px)',
                                        sm: '0 0 auto'
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#e8f5e9',
                                        borderColor: 'green',
                                    }
                                }
                            }}
                        >
                            {Object.entries(markerIcons).map(([key, value]) => (
                                <ToggleButton 
                                    key={key} 
                                    value={value.value}
                                    aria-label={t[`marker${key.charAt(0).toUpperCase() + key.slice(1)}`]}
                                >
                                    <Tooltip title={t[`marker${key.charAt(0).toUpperCase() + key.slice(1)}`]}>
                                        {value.icon}
                                    </Tooltip>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </SettingSection>

                    <SettingSection disabled={accountType === 'free'}>
                        <SettingTitle variant="subtitle1">
                            {isMobile ? t.autoRefreshMobile : t.autoRefresh}
                        </SettingTitle>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                            {isFrench 
                                ? "Actualiser automatiquement les disponibilités toutes les 10 minutes" 
                                : "Automatically refresh availabilities every 10 minutes"}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={accountType === 'free' ? false : autoRefreshEnabled}
                                    onChange={handleAutoRefreshChange}
                                    color="success"
                                    disabled={accountType === 'free'}
                                />
                            }
                            label={
                                <Typography variant="body2" sx={{ color: (accountType !== 'free' && autoRefreshEnabled) ? 'green' : 'text.secondary' }}>
                                    {isFrench 
                                        ? ((accountType !== 'free' && autoRefreshEnabled) ? "Activé" : "Désactivé")
                                        : ((accountType !== 'free' && autoRefreshEnabled) ? "Enabled" : "Disabled")}
                                </Typography>
                            }
                        />
                    </SettingSection>
                </InfoSection>
                
            </StyledPaper>
            <SubscriptionModal
                open={subscriptionModalOpen}
                onClose={() => setSubscriptionModalOpen(false)}
                currentPlan={accountType}
                isFrench={isFrench}
            />
        </StyledContainer>
    );
}