import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import axios from 'axios';
import { GoogleMap, Marker, LoadScript, InfoWindow } from '@react-google-maps/api';
import { styled } from '@mui/material/styles';
import { Tab, Tabs, Button, Grid, Box, Typography, Tooltip } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CircleIcon from '@mui/icons-material/Circle';
import { firebaseConfig } from '../firebase/config';

const isDevelopment = process.env.NODE_ENV === 'development';

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': {
    backgroundColor: '#ADD8E6',
  },
});

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const db = firebase.firestore();

const userLocationIcon = {
  url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="8" fill='#5384ED' opacity="1" />
      <circle cx="12" cy="12" r="11" fill='#5384ED' opacity="0.3" stroke="#4285F4" stroke-width="2" />
    </svg>
  `),
  scaledSize: { width: 24, height: 24 },
};

const markerColors = {
  default: '#EA4335',     // Red
  tennisRacquet: '#0074D9', // Blue
  tennisBall: '#FFC107',    // Dark yellow
  star: '#32CD32',        // Bright green
  heart: '#960A0A',       // Dark red
  dot: '#800080'          // Purple
};

// You can customize these values
const AVAILABLE_OPACITY = 1;
const UNAVAILABLE_OPACITY = 0.8;  // More transparent for unavailable courts

// Add your custom SVGs here
const customSVGs = {
  default: null,  // Will use default Google Maps marker path
  tennisRacquet: {
    type: 'full',
    content: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.52,2.49c-2.34-2.34-6.62-1.87-9.55,1.06c-1.6,1.6-2.52,3.87-2.54,5.46c-0.02,1.58,0.26,3.89-1.35,5.5l-4.24,4.24l1.42,1.42l4.24-4.24c1.61-1.61,3.92-1.33,5.5-1.35s3.86-0.94,5.46-2.54C21.38,9.11,21.86,4.83,19.52,2.49z"/><path fill="white" d="M10.32,11.68c-1.53-1.53-1.05-4.61,1.06-6.72s5.18-2.59,6.72-1.06c1.53,1.53,1.05,4.61-1.06,6.72S11.86,13.21,10.32,11.68z"/><path d="M18,15c-1.02,0-2.05,0.39-2.83,1.17c-1.56,1.56-1.56,4.09,0,5.66C15.95,22.61,16.98,23,18,23s2.05-0.39,2.83-1.17c1.56-1.56,1.56-4.09,0-5.66C20.05,15.39,19.02,15,18,15z"/><path fill="white" d="M18,17c0.53,0,1.04,0.21,1.41,0.59c0.78,0.78,0.78,2.05,0,2.83C19.04,20.79,18.53,21,18,21s-1.04-0.21-1.41-0.59c-0.78-0.78-0.78-2.05,0-2.83C16.96,17.21,17.47,17,18,17z"/></svg>`
  },
  tennisBall: {
    type: 'full',
    content: `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M3.81,6.28C2.67,7.9,2,9.87,2,12s0.67,4.1,1.81,5.72C6.23,16.95,8,14.68,8,12S6.23,7.05,3.81,6.28z"/></g><g><path d="M20.19,6.28C17.77,7.05,16,9.32,16,12s1.77,4.95,4.19,5.72C21.33,16.1,22,14.13,22,12S21.33,7.9,20.19,6.28z"/></g><g><path d="M14,12c0-3.28,1.97-6.09,4.79-7.33C17.01,3.02,14.63,2,12,2S6.99,3.02,5.21,4.67C8.03,5.91,10,8.72,10,12 s-1.97,6.09-4.79,7.33C6.99,20.98,9.37,22,12,22s5.01-1.02,6.79-2.67C15.97,18.09,14,15.28,14,12z"/></g></g></g></svg>`,
    needsBackground: true
  },
  star: {
    type: 'path',
    content: "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
  },
  heart: {
    type: 'path',
    content: "M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
  },
  dot: null
};

const Map = ({ isFrench, analytics, updateLastRefreshTime, autoRefreshEnabled, auth }) => {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 45.5017,
    lng: -73.5673,
  });
  const [refreshInterval, setRefreshInterval] = useState(5 * 60 * 1000); // Default 5 minutes
  const refreshTimeoutRef = useRef(null);
  const [tab, setTab] = useState(0);
  const [markerIcon, setMarkerIcon] = useState('default');
  const [isMarkersReady, setIsMarkersReady] = useState(false);
  const markerPreferencesUnsubscribe = useRef(null);
  const [accountType, setAccountType] = useState('free');
  const [quickLinks, setQuickLinks] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isPWA, setIsPWA] = useState(false);
  
  // Update default zoom based on screen size
  const defaultZoom = isMobile ? 11 : 12;  // Zoomed out slightly on mobile

  const formatDate = (dateStr, isFrench) => {
    // Create date object with explicit timezone handling for Eastern Time
    const [year, month, day] = dateStr.split('-').map(Number);
    // Months are 0-based in JavaScript Date
    const date = new Date(year, month - 1, day);
    
    const weekdays = {
      en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      fr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
    };
    const months = {
      en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      fr: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc']
    };
    
    const weekday = weekdays[isFrench ? 'fr' : 'en'][date.getDay()];
    const monthName = months[isFrench ? 'fr' : 'en'][date.getMonth()];
    
    return `${weekday} ${monthName} ${day}`;
  };

  const groupAvailabilitiesByDate = (stats) => {
    if (!stats) return {};
    
    const groupedStats = {};
    
    stats.forEach(stat => {
      // Extract just the date part from the datetime string
      const dateStr = stat.startDateTime.split('T')[0];
      
      if (!groupedStats[dateStr]) {
        groupedStats[dateStr] = [];
      }
      
      groupedStats[dateStr].push({
        ...stat,
        count: stat.count || (stat.reservationLinks ? stat.reservationLinks.length : 0)
      });
    });
    
    // Sort times within each date
    Object.keys(groupedStats).forEach(date => {
      groupedStats[date].sort((a, b) => {
        // Parse times directly from the ISO strings
        const timeA = a.startDateTime.split('T')[1].split(':').map(Number);
        const timeB = b.startDateTime.split('T')[1].split(':').map(Number);
        // Compare hours first, then minutes if hours are equal
        return timeA[0] * 60 + timeA[1] - (timeB[0] * 60 + timeB[1]);
      });
    });
    
    return groupedStats;
  };

  useEffect(() => {
    // Subscribe to quickLinks document
    const unsubscribe = firebase.firestore()
      .collection('overview')
      .doc('quickLinks')
      .onSnapshot((doc) => {
        if (doc.exists) {
          setQuickLinks(doc.data().quickLinks);
        }
      });

    return () => unsubscribe();
  }, []);

  const formatAvailability = (stat) => {
    const [hours, minutes] = stat.startDateTime.split('T')[1].split(':').map(Number);
    const formattedStart = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    
    return (
      <Grid item xs={6} sm={4} md={3} key={stat.startDateTime}>
        <Button
          onClick={() => {
            console.log(quickLinks);
            console.log(stat.linkSearchKey);
            console.log(quickLinks[stat.linkSearchKey]);
            if (quickLinks && stat.linkSearchKey) {
              const actualLink = quickLinks[stat.linkSearchKey];
              if (actualLink) {
                window.open(actualLink, "_blank");
                logEvent(analytics, 'click_availability_link', { 
                  link: actualLink, 
                  startDate: stat.startDateTime,
                  startTime: formattedStart 
                });
              } else {
                console.error('Link not found in quickLinks');
              }
            } else {
              console.error('QuickLinks not loaded or linkSearchKey missing');
            }
          }}
          variant="contained"
          fullWidth
          style={{ 
            backgroundColor: '#64b5f6', 
            color: 'white', 
            whiteSpace: 'nowrap', 
            padding: '6px 12px',
            fontSize: '0.875rem'
          }}
        >
          {formattedStart} ({stat.count})
        </Button>
      </Grid>
    );
  };

  useEffect(() => {
    /*if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserPosition({ lat: latitude, lng: longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          logEvent(analytics, 'get_user_position', { status: 'success', lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting user position:', error);
          logEvent(analytics, 'get_user_position', { status: 'error', message: error.message });
        }
      );
    } else {
      logEvent(analytics, 'get_user_position', { status: 'error', message: 'Geolocation is not supported by this browser.' });
      console.error('Geolocation is not supported by this browser.');
    }*/
  }, []);

  useEffect(() => {
    const getMarkers = async () => {
      try {
        setIsMarkersReady(false);
        const overviewSnapshot = await db.collection('overview').doc('loadedSites').get();
        const newMarkers = [];
        for (const doc in overviewSnapshot.data()) {
          const data = overviewSnapshot.data()[doc];
          let lat, lng;

          if (data.sitePosition) {
            lat = data.sitePosition.lat;
            lng = data.sitePosition.lng;
          }

          newMarkers.push({
            id: doc.id,
            sitePosition: { lat, lng },
            label: data.siteName,
            siteAddress: data.siteAddress,
            sitePhone: data.sitePhone,
            stats: data.stats,
          });
        }

        setMarkers(newMarkers);
        
        // Update selected marker if it exists
        if (selectedMarker) {
          const updatedMarker = newMarkers.find(marker => 
            marker.sitePosition.lat === selectedMarker.sitePosition.lat && 
            marker.sitePosition.lng === selectedMarker.sitePosition.lng
          );
          if (updatedMarker) {
            setSelectedMarker(updatedMarker);
          }
        }

        setIsMarkersReady(true);
        
        // Get new refresh interval and update last refresh time
        const newInterval = await updateLastRefreshTime();
        setRefreshInterval(newInterval);

        // Schedule next refresh only if auto-refresh is enabled
        if (autoRefreshEnabled) {
          refreshTimeoutRef.current = setTimeout(() => {
            getMarkers();
          }, newInterval);
        }
      } catch (err) {
        console.error("Error while fetching markers: ", err);
        setIsMarkersReady(true); // Set to true even on error to prevent infinite loading
      }
    };

    getMarkers();

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [updateLastRefreshTime, autoRefreshEnabled]);

  useEffect(() => {
    // Cleanup previous listener if exists
    if (markerPreferencesUnsubscribe.current) {
      markerPreferencesUnsubscribe.current();
    }

    setIsMarkersReady(false); // Reset markers when marker icon changes
    // Set up real-time listener for marker preferences and account type
    if (firebase.auth().currentUser) {
      // First, get the user's account type
      firebase.firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((userDoc) => {
          if (userDoc.exists) {
            setAccountType(userDoc.data().accountType || 'free');
          } else {
            setAccountType('free');
          }
        });

      // Then, get marker preferences
      markerPreferencesUnsubscribe.current = firebase.firestore()
        .collection('userPreferences')
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data();
            // Only set custom marker icon if user is not on free plan
            if (data.markerIcon && accountType !== 'free') {
              setMarkerIcon(data.markerIcon);
            } else {
              setMarkerIcon('default');
            }
          }
          setIsMarkersReady(true);
        }, (error) => {
          console.error("Error listening to marker preferences:", error);
          setIsMarkersReady(true);
        });
    } else {
      setIsMarkersReady(true);
    }

    // Cleanup listener on unmount or when auth changes
    return () => {
      if (markerPreferencesUnsubscribe.current) {
        markerPreferencesUnsubscribe.current();
      }
    };
  }, [firebase.auth().currentUser?.uid, accountType]); // Add accountType to dependencies

  const getMarkerIcon = (hasAvailabilities) => {
    // Force default marker for free plan users
    const effectiveMarkerIcon = accountType === 'free' ? 'default' : markerIcon;
    const opacity = hasAvailabilities ? AVAILABLE_OPACITY : UNAVAILABLE_OPACITY;
    const color = hasAvailabilities ? markerColors[effectiveMarkerIcon] : '#757575';
    const size = 40;
    
    // Handle full SVG content
    if (customSVGs[effectiveMarkerIcon]?.type === 'full') {
      if (!customSVGs[effectiveMarkerIcon].content) return null;
      
      // Special handling for tennis ball with background
      if (customSVGs[effectiveMarkerIcon].needsBackground) {
        return {
          url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" fill="white"/>
              <g fill="${color}" opacity="${opacity}">
                ${customSVGs[effectiveMarkerIcon].content}
              </g>
            </svg>
          `)}`,
          scaledSize: new window.google.maps.Size(size, size),
        };
      }
      
      return {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="${color}" opacity="${opacity}">
              ${customSVGs[effectiveMarkerIcon].content}
            </g>
          </svg>
        `)}`,
        scaledSize: new window.google.maps.Size(size, size),
      };
    }

    // Handle path-only SVG content
    if (customSVGs[effectiveMarkerIcon]?.type === 'path') {
      return {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill="${color}" opacity="${opacity}" d="${customSVGs[effectiveMarkerIcon].content}"/>
          </svg>
        `)}`,
        scaledSize: new window.google.maps.Size(size, size),
      };
    }

    // Default marker with custom color
    if (effectiveMarkerIcon === 'default') {
      return {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill="${color}" opacity="${opacity}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
          </svg>
        `)}`,
        scaledSize: new window.google.maps.Size(size, size),
      };
    }

    // Simple dot marker
    return {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="8" fill="${color}" opacity="${opacity}"/>
        </svg>
      `)}`,
      scaledSize: new window.google.maps.Size(size * 0.8, size * 0.8),
    };
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    // Check if app is running as PWA
    const checkIfPWA = () => {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      const isFullscreen = window.matchMedia('(display-mode: fullscreen)').matches;
      const isPWAMode = isStandalone || isFullscreen || window.navigator.standalone;
      setIsPWA(isPWAMode);
    };

    // Set initial values
    handleResize();
    checkIfPWA();

    // Add event listeners
    window.addEventListener('resize', handleResize);
    window.matchMedia('(display-mode: standalone)').addListener(checkIfPWA);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.matchMedia('(display-mode: standalone)').removeListener(checkIfPWA);
    };
  }, []);

  // Calculate the appropriate height based on device and mode
  const getMapHeight = () => {
    if (!isMobile) return '87vh';
    return isPWA ? '83vh' : '78vh'; // More height in PWA mode since there's no browser UI
  };

  return (
    <GoogleMap
      mapContainerStyle={{ 
        height: getMapHeight(),
        width: '100%' 
      }}
      center={mapCenter}
      zoom={defaultZoom}
      options={{ 
        clickableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        gestureHandling: 'greedy',
        zoomControl: !isMobile,
      }}

    >
      {userPosition && (
        <Marker
          position={userPosition}
          icon={userLocationIcon}
        />
      )}
      {isMarkersReady && markers.map((marker) => (
        <Marker
          key={marker.id}
          position={marker.sitePosition}
          onClick={() => {
            setSelectedMarker(marker);
            setMapCenter(marker.sitePosition);
            logEvent(analytics, 'click_marker', { siteName: marker.label });
          }}
          opacity={marker.stats && marker.stats.length > 0 ? 1 : 0.3}
          icon={getMarkerIcon(marker.stats && marker.stats.length > 0)}
          title={!marker.stats || marker.stats.length === 0 ? (isFrench ? "Aucune disponibilité" : "No availabilities") : ""}
          zIndex={marker.stats && marker.stats.length > 0 ? 2 : 1}
        />
      ))}

      {selectedMarker && (
        <InfoWindow
          position={{
            lat: selectedMarker.sitePosition.lat,
            lng: selectedMarker.sitePosition.lng,
          }}
          options={{
            pixelOffset: new window.google.maps.Size(0, -45), // Adjust -40 based on the height of the InfoWindow
          }}
          onCloseClick={() => {
            setSelectedMarker(null);
          }}
        >
          <div style={{ color: 'black', maxWidth: '400px' }}>
            <h2>{selectedMarker.label}</h2>
            <p>{selectedMarker.siteAddress}</p>
            <p>{selectedMarker.sitePhone}</p>
            
            {selectedMarker.stats && selectedMarker.stats.length > 0 ? (
              <div>
                <Typography style={{ marginTop: "2em" }} variant="h5" component="h2" align="center">
                  {isFrench ? "Disponibilités" : "Availabilities"}
                </Typography>
                
                {(() => {
                  const groupedStats = groupAvailabilitiesByDate(selectedMarker.stats);
                  const dates = Object.keys(groupedStats).sort();
                  
                  return (
                    <>
                      <Box sx={{ 
                        borderBottom: 1, 
                        borderColor: 'divider', 
                        marginBottom: 2,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}>
                        <Tabs 
                          value={tab} 
                          onChange={(e, newValue) => setTab(newValue)}
                          variant="scrollable"
                          scrollButtons={true}
                          allowScrollButtonsMobile
                          sx={{
                            maxWidth: '100%',
                            minHeight: '48px',
                            '& .MuiTabs-flexContainer': {
                              justifyContent: 'flex-start'
                            },
                            '& .MuiTabs-scrollButtons': {
                              '&.Mui-disabled': {
                                opacity: 0.3,
                              },
                            },
                            '& .MuiTab-root': {
                              minWidth: '80px',
                              padding: '6px 12px'
                            }
                          }}
                        >
                          {dates.map((date, index) => (
                            <Tab 
                              key={date}
                              label={formatDate(date, isFrench)}
                              value={index}
                              style={{ minWidth: '80px', padding: '6px 12px' }}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      
                      {dates.map((date, index) => (
                        <div
                          key={date}
                          role="tabpanel"
                          hidden={tab !== index}
                          style={{ marginTop: '1em' }}
                        >
                          {tab === index && (
                            <Grid 
                              container 
                              spacing={1} 
                              justifyContent="center"
                              sx={{ 
                                margin: 0,
                                width: '100%',
                                padding: '0 8px'
                              }}
                            >
                              {groupedStats[date].map(stat => formatAvailability(stat))}
                            </Grid>
                          )}
                        </div>
                      ))}
                    </>
                  );
                })()}
              </div>
            ) : (
              <Typography variant="body1" align="center" style={{ marginTop: '2em' }}>
                {isFrench ? "Aucune disponibilité" : "No availabilities"}
              </Typography>
            )}
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default Map;
