import React, { useState, useEffect } from 'react';
import { 
    Button, 
    TextField, 
    Typography, 
    Container, 
    IconButton, 
    CssBaseline,
    Divider,
    Card,
    Box,
    CircularProgress
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Google as GoogleIcon } from '@mui/icons-material';
import { logEvent } from "firebase/analytics";
import { useNavigate, Navigate } from 'react-router-dom';

const translations = {
    en: {
        signIn: "Sign In",
        signUp: "Sign Up",
        forgotPassword: "Forgot Password?",
        hello: "Hello",
        logOut: "Log Out",
        email: "Email Address",
        password: "Password",
        firstName: "First Name",
        lastName: "Last Name",
        signInWithGoogle: "Continue with Google",
        noAccount: "Don't have an account?",
        or: "or"
    },
    fr: {
        signIn: "Se connecter",
        signUp: "S'inscrire",
        forgotPassword: "Mot de passe oublié?",
        hello: "Bonjour",
        logOut: "Se déconnecter",
        email: "Adresse courriel",
        password: "Mot de passe",
        firstName: "Prénom",
        lastName: "Nom",
        signInWithGoogle: "Continuer avec Google",
        noAccount: "Vous n'avez pas de compte?",
        or: "ou"
    }
};

const StyledContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#282c34',
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        minHeight: '86vh'
    }
}));

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '380px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    overflowY: 'auto',
    maxHeight: '75vh',
    [theme.breakpoints.up('sm')]: {
        maxHeight: '80vh'
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: '320px',
        padding: theme.spacing(1.5),
        '& .MuiTextField-root': {
            marginTop: '8px',
            marginBottom: '8px'
        }
    }
}));

const StyledForm = styled('form')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0.5)
    }
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2, 0, 1),
    padding: theme.spacing(1),
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 0, 0.5),
        padding: theme.spacing(0.75)
    }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 0)
    }
}));

const StyledGoogleButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    backgroundColor: '#fff',
    color: '#757575',
    border: '1px solid #dadce0',
    '&:hover': {
        backgroundColor: '#f8f9fa',
        borderColor: '#dadce0',
        boxShadow: '0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)'
    },
    padding: theme.spacing(1),
    fontSize: '0.95rem',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '& .MuiButton-startIcon': {
        marginRight: theme.spacing(2),
        position: 'absolute',
        left: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.75),
        fontSize: '0.9rem',
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(1.5),
            left: theme.spacing(1.5)
        }
    }
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    color: '#282c34',
    fontWeight: 500,
    fontSize: '1.75rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        marginBottom: theme.spacing(1)
    }
}));

const StyledGreeting = styled(Typography)(({ theme }) => ({
    color: '#fff',
    marginRight: theme.spacing(2)
}));

const StyledLogoutButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    borderColor: '#fff',
    '&:hover': {
        borderColor: '#fff',
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
}));

export default function LoginPage({ isFrench, analytics }) {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [user, setUser] = useState(null);
    const [mode, setMode] = useState('login'); // can be 'login', 'signup', or 'forgotPassword'
    const [googleLoading, setGoogleLoading] = useState(false);
    const navigate = useNavigate();

    const t = isFrench ? translations.fr : translations.en;

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (mode === 'login') {
            firebase.auth().signInWithEmailAndPassword(formData.email, formData.password)
                .then(userCredential => {
                    setFormData({ email: '', password: '' });
                    setError('');
                    logEvent(analytics, 'login', {
                        method: 'email'
                    });
                    navigate('/');
                })
                .catch(error => {
                    setError(error.message);
                    logEvent(analytics, 'auth_error', {
                        error: error.message,
                        type: 'login'
                    });
                });
        } 
    
        else if (mode === 'signup') {
            try {
                // Create the user account first
                const userCredential = await firebase.auth().createUserWithEmailAndPassword(formData.email, formData.password);
                
                console.log(userCredential.user);
                // Create the user document in Firestore immediately after account creation
                await firebase.firestore().collection('users').doc(userCredential.user.uid).set({
                    displayName: `${formData.firstName} ${formData.lastName}`,
                    email: userCredential.user.email,
                    accountType: 'free',
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                });

                // Only after the document is created, update the profile
                await userCredential.user.updateProfile({
                    displayName: `${formData.firstName} ${formData.lastName}`
                });

                // Reset form and navigate
                setFormData({ email: '', password: '', firstName: '', lastName: '' });
                setError('');
                logEvent(analytics, 'sign_up', {
                    method: 'email'
                });
                navigate('/');
            } catch (error) {
                setError(error.message);
                logEvent(analytics, 'auth_error', {
                    error: error.message,
                    type: 'signup'
                });
            }
        } 
    
        else if (mode === 'forgotPassword') {
            firebase.auth().sendPasswordResetEmail(formData.email)
                .then(() => {
                    console.log('Password reset email sent!');
                    setFormData({ email: '', password: '' });
                    setMode('login');
                    setError('Password reset email sent!');
                    logEvent(analytics, 'password_reset_email_sent');
                })
                .catch(error => {
                    setError(error.message);
                    logEvent(analytics, 'auth_error', {
                        error: error.message,
                        type: 'password_reset'
                    });
                });
        }
    };
    
    const handleLogout = () => {
        firebase.auth().signOut().then(() => {
            setUser(null);
            logEvent(analytics, 'logout');
        });
    };

    const handleGoogleSignIn = async () => {
        setGoogleLoading(true);
        const provider = new firebase.auth.GoogleAuthProvider();
        
        try {
            const result = await firebase.auth().signInWithPopup(provider);
            
            // Check if this is a new user
            if (result.additionalUserInfo.isNewUser) {
                // Only create/update the user document if this is a new user
                await firebase.firestore().collection('users').doc(result.user.uid).set({
                    displayName: result.user.displayName,
                    email: result.user.email,
                    accountType: 'free',
                    photoURL: result.user.photoURL,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                });

                logEvent(analytics, 'sign_up', {
                    method: 'google'
                });
            } else {
                logEvent(analytics, 'login', {
                    method: 'google'
                });
            }
            
            navigate('/');
        } catch (error) {
            setError(error.message);
            logEvent(analytics, 'auth_error', {
                error: error.message,
                type: 'google_login'
            });
        } finally {
            setGoogleLoading(false);
        }
    };

    if (user) {
        return <Navigate to="/" />;
    }

    return (
        <Container component="main" maxWidth="xs" style={{ padding: 0 }}>
            <StyledContainer>
                <CssBaseline />
                <StyledCard>
                    <StyledHeading component="h1" variant="h4">
                        {mode === 'login' && t.signIn}
                        {mode === 'signup' && t.signUp}
                        {mode === 'forgotPassword' && t.forgotPassword}
                    </StyledHeading>
                    
                    {error && <Typography color="error" style={{marginBottom: '0.5rem'}}>{error}</Typography>}
                    
                    <StyledForm onSubmit={handleSubmit} noValidate>
                        {mode === 'signup' && (
                            <>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={t.firstName}
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label={t.lastName}
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </>
                        )}
                        <TextField
                            variant="outlined"
                            margin="dense"
                            required
                            fullWidth
                            id="email"
                            label={t.email}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />

                        {(mode === 'login' || mode === 'signup') && (
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="password"
                                label={t.password}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                value={formData.password}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                            size="small"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    ),
                                }}
                            />
                        )}

                        <StyledSubmitButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            {mode === 'login' && t.signIn}
                            {mode === 'signup' && t.signUp}
                            {mode === 'forgotPassword' && t.forgotPassword}
                        </StyledSubmitButton>

                        <StyledDivider>{t.or}</StyledDivider>

                        <StyledGoogleButton
                            fullWidth
                            variant="outlined"
                            onClick={handleGoogleSignIn}
                            startIcon={!googleLoading && <GoogleIcon style={{ width: 18, height: 18 }} />}
                            size="large"
                            disabled={googleLoading}
                        >
                            {googleLoading ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CircularProgress size={20} color="inherit" />
                                </Box>
                            ) : (
                                t.signInWithGoogle
                            )}
                        </StyledGoogleButton>

                        <Box mt={1} textAlign="center">
                            {mode === 'login' && (
                                <>
                                    <Button 
                                        color="primary" 
                                        onClick={() => setMode('forgotPassword')}
                                        size="small"
                                    >
                                        {t.forgotPassword}
                                    </Button>
                                    <Typography variant="body2" style={{marginTop: '0.5rem'}}>
                                        {t.noAccount}{' '}
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                setFormData({ email: '', password: '' });
                                                setMode('signup');
                                            }}
                                            size="small"
                                        >
                                            {t.signUp}
                                        </Button>
                                    </Typography>
                                </>
                            )}

                            {mode !== 'login' && (
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setFormData({ email: '', password: '' });
                                        setMode('login');
                                    }}
                                    size="small"
                                >
                                    {t.signIn}
                                </Button>
                            )}
                        </Box>
                    </StyledForm>
                </StyledCard>
            </StyledContainer>
        </Container>
    );
}