// CourtAvailabilityView.js

import React, { useEffect, useState, useRef } from 'react';
import { Typography, Input, CircularProgress, Button, Collapse, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import CourtAvailabilityTable from './CourtAvailabilityTable';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getDistance } from 'geolib';
import { logEvent } from "firebase/analytics";
import { useNavigate } from 'react-router-dom';
import { firebaseConfig } from '../firebase/config';

function accentAndCaseRemover(initialString) {
  if (initialString) {
    const replacementDict = {
      'a': ['â', 'à', 'ä', 'Â', 'À', 'Ä'],
      'e': ['é', 'ê', 'ë', 'è', 'É', 'È', 'Ê', 'Ë'],
      'c': ['ç', 'Ç'],
      'i': ['î', 'ï', 'ì', 'Î', 'Ï', 'Ì'],
      'o': ['ô', 'ö', 'ò', 'Ô', 'Ö', 'Ò'],
      'u': ['û', 'ü', 'ù', 'Û', 'Ü', 'Ù']
    }
    for (var key in replacementDict) {
      replacementDict[key].forEach(
        specialLetter => {
          initialString = initialString.replace(new RegExp(specialLetter, 'g'), key);
        }
      );
    }

    initialString = initialString.toLowerCase();
  }
  return initialString
}

const translations = {
    en: {
        favoriteLimitTitle: "Favorite Limit Reached",
        favoriteLimitMessage: "Free plan allows only 1 favorite court. Please remove the court from favorites or upgrade to Premium plan to add more favorites.",
        upgrade: "Upgrade",
        close: "Close"
    },
    fr: {
        favoriteLimitTitle: "Limite de favoris atteinte",
        favoriteLimitMessage: "Le plan gratuit permet d'avoir un seul terrain favori. Veuillez retirer le terrain de vos favoris ou mettre à niveau au plan Premium pour en ajouter plus.",
        upgrade: "Mettre à niveau",
        close: "Fermer"
    }
};

const CourtAvailabilityView = ({ 
  searchField, 
  onSearchFieldChange, 
  isFrench, 
  analytics, 
  setSelectedTab, 
  updateLastRefreshTime,
  autoRefreshEnabled,
  auth
}) => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState(searchField || '');
    const [isMobile, setIsMobile] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [favoritesLoaded, setFavoritesLoaded] = useState(false);
    const [geoPermission, setGeoPermission] = useState('unknown');
    const [favorites, setFavorites] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [showNoAvailability, setShowNoAvailability] = useState(false);
    const [expandedCourts, setExpandedCourts] = useState(new Set());
    const [refreshInterval, setRefreshInterval] = useState(5 * 60 * 1000); // Default 5 minutes
    const scrollPositionRef = useRef(0);
    const isRefreshing = useRef(false);
    const refreshTimeoutRef = useRef(null);
    const [showFavoriteLimitDialog, setShowFavoriteLimitDialog] = useState(false);
    const [accountType, setAccountType] = useState('free');
    const navigate = useNavigate();

    // Function to save scroll position
    const saveScrollPosition = () => {
        if (!isRefreshing.current) {
            scrollPositionRef.current = Math.max(0, Math.floor(window.scrollY));
        }
    };

    // Function to restore scroll position
    const restoreScrollPosition = () => {
        const scrollTarget = Math.max(0, Math.floor(Number(scrollPositionRef.current)));
        
        // Use multiple attempts with increasing delays
        const attemptScroll = (attempt = 1) => {
            if (attempt > 3) {
                isRefreshing.current = false;
                return;
            }

            setTimeout(() => {
                window.scrollTo(0, scrollTarget);
                
                // Verify if scroll worked
                if (Math.abs(window.scrollY - scrollTarget) > 10) {
                    attemptScroll(attempt + 1);
                } else {
                    isRefreshing.current = false;
                }
            }, attempt * 100);
        };

        requestAnimationFrame(() => attemptScroll());
    };

    // Add state to track selected dates for each court
    const [selectedDates, setSelectedDates] = useState({});

    // Modify the handleTabChange in CourtAvailabilityTable to update the parent's state
    const handleTabChange = (courtId, newDate) => {
        setSelectedDates(prev => ({
            ...prev,
            [courtId]: newDate
        }));
    };

    // Add useEffect to get user's account type
    useEffect(() => {
        if (auth) {
            const user = firebase.auth().currentUser;
            if (user) {
                const unsubscribe = firebase.firestore().collection('users').doc(user.uid).onSnapshot((doc) => {
                    if (doc.exists) {
                        const userType = doc.data().accountType || 'free';
                        setAccountType(userType);
                        
                        // If user was downgraded to free, enforce favorite limit
                        if (userType === 'free' && favorites.length > 1) {
                            const firstFavorite = [favorites[0]];
                            setFavorites(firstFavorite);
                            // Update database with only the first favorite
                            firebase.firestore().collection('userFavorites').doc(user.uid).set({
                                favorites: firstFavorite
                            });
                        }
                    }
                });
                
                // Cleanup subscription
                return () => unsubscribe();
            }
        } else {
            setAccountType('free');
        }
    }, [auth]); // Remove favorites from dependencies

    const FavoriteLimitDialog = () => (
        <Dialog 
            open={showFavoriteLimitDialog} 
            onClose={() => setShowFavoriteLimitDialog(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {isFrench ? translations.fr.favoriteLimitTitle : translations.en.favoriteLimitTitle}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {isFrench ? translations.fr.favoriteLimitMessage : translations.en.favoriteLimitMessage}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowFavoriteLimitDialog(false)}>
                    {isFrench ? translations.fr.close : translations.en.close}
                </Button>
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={() => {
                        navigate('/profile');
                        setShowFavoriteLimitDialog(false);
                    }}
                >
                    {isFrench ? translations.fr.upgrade : translations.en.upgrade}
                </Button>
            </DialogActions>
        </Dialog>
    );

    const updateFavorites = (newFavorites) => {
        const user = firebase.auth().currentUser;
        if (!user) return favorites;

        // If user is on free plan and trying to add more than one favorite
        if (accountType === 'free' && newFavorites.length > 1) {
            // Show the limit dialog
            setShowFavoriteLimitDialog(true);
            // Log the attempt
            logEvent(analytics, 'favorite_limit_reached', {
                currentCount: favorites.length,
                attemptedCount: newFavorites.length
            });
            // Return current favorites to keep the star in its current state
            return favorites;
        }

        // Process the update if we're here
        processFavoriteUpdate(newFavorites);
        return newFavorites;
    };

    const processFavoriteUpdate = async (newFavorites) => {
        const user = firebase.auth().currentUser;
        if (!user) return;

        // Update database first
        try {
            await firebase.firestore().collection('userFavorites').doc(user.uid).set({
                favorites: newFavorites
            });

            let updatedDataToShow = [...dataToShow];
        
            // If a court is added to favorites, remove it from dataToShow
            for (let fav of newFavorites) {
                if (!favorites.includes(fav)) {
                    updatedDataToShow = updatedDataToShow.filter(item => item.id !== fav.toString());
                }
            }
        
            // If a court is removed from favorites, add it back to dataToShow
            for (let fav of favorites) {
                if (!newFavorites.includes(fav) && data.some(item => item.id === fav.toString())) {
                    const courtToAddBack = data.find(item => item.id === fav.toString());
                    updatedDataToShow.push(courtToAddBack);
                }
            }
        
            // Re-sort dataToShow based on distance
            updatedDataToShow.sort((a, b) => a.distanceInKm - b.distanceInKm);
        
            setDataToShow(updatedDataToShow);
            setFavorites(newFavorites);
        } catch (error) {
            console.error('Error updating favorites:', error);
        }
    };

    const handleResize = () => {
      const isMobile = window.innerWidth < 600;
      setIsMobile(isMobile);
    };

    const handleCourtExpansion = (courtId, isExpanded) => {
        setExpandedCourts(prev => {
            const newSet = new Set(prev);
            if (isExpanded) {
                newSet.add(courtId);
            } else {
                newSet.delete(courtId);
            }
            return newSet;
        });
    };

    useEffect(() => {
      const fetchData = async () => {
          isRefreshing.current = true;
          console.log("Setting isRefreshing to true in fetchData");
          const currentScroll = window.scrollY;
          scrollPositionRef.current = currentScroll;
          
          setIsLoading(true);
      
          if (!firebase.apps.length) {
              firebase.initializeApp(firebaseConfig);
          }
      
          const firestore = firebase.firestore();
      
          try {
              // Fetch all data in parallel
              const [overviewSnapshot, userDoc, newInterval] = await Promise.all([
                  firestore.collection('overview').doc('loadedSitesTable').get(),
                  firebase.auth().currentUser ? firestore.collection('userFavorites').doc(firebase.auth().currentUser.uid).get() : null,
                  updateLastRefreshTime()
              ]);

              const snapShotData = overviewSnapshot.data();
              
              // Get user's location once
              let userLocation = null;
              if ("geolocation" in navigator) {
                  try {
                      const position = await new Promise((resolve, reject) => {
                          navigator.geolocation.getCurrentPosition(resolve, reject);
                      });
                      userLocation = {
                          latitude: position.coords.latitude,
                          longitude: position.coords.longitude
                      };
                      setGeoPermission('granted');
                  } catch (error) {
                      console.error("Error getting current location:", error);
                      if (error.code === error.PERMISSION_DENIED) {
                          setGeoPermission('denied');
                      }
                  }
              }

              // Process all courts at once
              const updatedData = Object.entries(snapShotData).map(([doc, courtData]) => {
                  const court = { ...courtData, id: doc, distanceInKm: null };
                  
                  if (userLocation) {
                      const distanceInMeters = getDistance(userLocation, court.sitePosition);
                      court.distanceInKm = (distanceInMeters / 1000).toFixed(2);
                  }
                  
                  return court;
              });

              // Batch state updates
              setData(updatedData);
              setRefreshInterval(newInterval);
              if (userDoc && userDoc.exists) {
                  setFavorites(userDoc.data().favorites || []);
              }
              setFavoritesLoaded(true);
              setIsLoading(false);

              // Restore scroll position after a short delay to ensure content is rendered
              setTimeout(restoreScrollPosition, 300);
          } catch (error) {
              console.error("Error fetching data:", error);
              setIsLoading(false);
              isRefreshing.current = false;
              console.log("Setting isRefreshing to false in fetchData");
          }
      };    

      fetchData();
      handleResize();
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', saveScrollPosition);
    
      return () => {
        if (refreshTimeoutRef.current) {
            clearTimeout(refreshTimeoutRef.current);
        }
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', saveScrollPosition);
      };
    }, [updateLastRefreshTime]);

    // Separate effect for handling auto-refresh scheduling
    useEffect(() => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }

      if (autoRefreshEnabled) {
        refreshTimeoutRef.current = setTimeout(() => {
          const fetchData = async () => {
            isRefreshing.current = true;
            console.log("Setting isRefreshing to true in fetchData of autoRefresh");
            const currentScroll = window.scrollY;
            scrollPositionRef.current = currentScroll;
            
            try {
              const [overviewSnapshot, userDoc, newInterval] = await Promise.all([
                firebase.firestore().collection('overview').doc('loadedSitesTable').get(),
                firebase.auth().currentUser ? firebase.firestore().collection('userFavorites').doc(firebase.auth().currentUser.uid).get() : null,
                updateLastRefreshTime()
              ]);

              const snapShotData = overviewSnapshot.data();
              const updatedData = Object.entries(snapShotData).map(([doc, courtData]) => ({
                ...courtData,
                id: doc,
                distanceInKm: data.find(item => item.id === doc)?.distanceInKm
              }));

              setData(updatedData);
              setRefreshInterval(newInterval);
              if (userDoc && userDoc.exists) {
                setFavorites(userDoc.data().favorites || []);
              }
              setTimeout(restoreScrollPosition, 300);
            } catch (error) {
              console.error("Error fetching data:", error);
            } finally {
              // nothing
            }
          };

          fetchData();
        }, refreshInterval);
      }

      return () => {
        if (refreshTimeoutRef.current) {
          clearTimeout(refreshTimeoutRef.current);
        }
      };
    }, [autoRefreshEnabled, refreshInterval, data]);

    useEffect(() => {
        if (searchQuery.length >= 3 || (data !== null && data.length > 0 && data[0].distanceInKm !== null)) {
            setIsLoading(true);
            const timer = setTimeout(() => {
                const filtered = data.filter((item) => {
                    if (searchQuery.length >= 3) {
                        let cleanSiteName = accentAndCaseRemover(item.siteName.trim().replace(/ +/g, "").toLowerCase());
                        let cleanSearchQuery = accentAndCaseRemover(searchQuery.trim().replace(/ +/g, "").toLowerCase());
                        return cleanSiteName.includes(cleanSearchQuery);
                    }
                    if (item.distanceInKm !== null) {
                        return true;
                    }
                    return false;
                });

                // First, split courts into available and unavailable
                const { available, unavailable } = getFilteredCourts(filtered);
                
                // Sort each group by distance
                const sortedAvailable = available.sort((a, b) => a.distanceInKm - b.distanceInKm);
                const sortedUnavailable = unavailable.sort((a, b) => a.distanceInKm - b.distanceInKm);
                
                // Combine them with available courts first, then take top 10
                const combinedSorted = [...sortedAvailable, ...sortedUnavailable].slice(0, 10);

                if (favoritesLoaded) {
                    setDataToShow(combinedSorted.filter(item => !favorites.includes(parseInt(item.id))));
                } else {
                    setDataToShow([]);
                }

                setIsLoading(false);
            }, 500);

            return () => {
                clearTimeout(timer);
            };
        } else {
            // Clear results and stop loading if search query is too short
            setDataToShow([]);
            setIsLoading(false);
        }
    }, [data, searchQuery, favoritesLoaded]);
    

  const calculateDistance = (sitePosition, callback) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          setGeoPermission('granted');

          const distanceInMeters = getDistance(currentLocation, sitePosition);
          const distanceInKm = (distanceInMeters / 1000).toFixed(2);

          callback(distanceInKm);
        },
        (error) => {
          console.error("Error getting current location:", error);
          if (error.code === error.PERMISSION_DENIED) {
              setGeoPermission('denied');
          }
          callback(null);
        }
      );
    } else if (geoPermission === 'denied') {
      console.error("Geolocation permission was denied.");
    }else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const hasAvailabilities = (court) => {
    return court.tableStats && court.tableStats.some(stat => 
        Object.values(stat.availability).some(slots => slots && slots.length > 0)
    );
  };

  // Split courts into those with and without availabilities
  const getFilteredCourts = (courts) => {
    const available = [];
    const unavailable = [];
    
    courts.forEach(court => {
        if (hasAvailabilities(court)) {
            available.push(court);
        } else {
            unavailable.push(court);
        }
    });

    return { available, unavailable };
  };

  return (
    <div style={{ verticalAlign: 'top', minHeight: '84vh' }}>
        <Typography
            variant="h4"
            component="h1"
            style={{
                fontSize: isMobile ? '24px' : '36px',
                marginBottom: '0.5em',
                marginTop: '1em',
            }}
        >
            {isFrench ? "Rechercher le nom du terrain" : "Search court name"}
        </Typography>
        <Input
            type="text"
            style={{
                height: isMobile ? '40px' : '50px',
                width: isMobile ? 'auto' : 'auto',
                backgroundColor: 'white',
                marginBottom: '1em',
            }}
            value={searchQuery}
            onChange={(e) => {
                setSearchQuery(e.target.value)
                logEvent(analytics, 'search_change', { search_term: e.target.value });
            }}
            placeholder={isFrench ? "Ex: La Fontaine" : "Ex: La Fontaine"}
        />
        {isLoading ? (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress size={isMobile ? 60 : 80} style={{ color: '#64b5f6', marginTop: "1em" }} />
            </div>
        ) : (
            <>
                {favorites.length > 0 && (
                    <>
                        <Typography variant="h4" component="h2" style={{fontSize: isMobile ? '24px' : '36px', marginTop: "1em", marginBottom: "1.5em"}}>
                            {isFrench ? "Mes favoris" : "My Favorites"}
                        </Typography>
                        {favorites.map(favId => {
                            const favCourt = data.find(court => court.id == favId);
                            return favCourt && <CourtAvailabilityTable 
                                style={{ marginTop: "20px" }} 
                                data={favCourt} 
                                key={favCourt.id} 
                                isFrench={isFrench} 
                                analytics={analytics} 
                                setFavorites={updateFavorites} 
                                prevFavorites={favorites} 
                                setSelectedTab={setSelectedTab}
                                isExpanded={expandedCourts.has(favCourt.id)}
                                onExpansionChange={(isExpanded) => handleCourtExpansion(favCourt.id, isExpanded)}
                                selectedDate={selectedDates[favCourt.id]}
                                onDateChange={handleTabChange}
                            />
                        })}
                        <hr />
                    </>
                )}
                {dataToShow.length === 0 ? (
                  searchQuery.length >= 3 ? (
                    <Typography variant="body1" style={{ textAlign: 'center', marginTop: '2em' }}>
                        {isFrench ? "Aucun terrain trouvé pour votre recherche." : "No courts found for your search."}
                    </Typography>
                  ) : searchQuery.length > 0 ? (
                    <Typography variant="body1" style={{ textAlign: 'center', marginTop: '2em' }}>
                        {isFrench ? "Veuillez entrer au moins 3 caractères." : "Please enter at least 3 characters."}
                    </Typography>
                  ) : (
                    <Typography variant="body1" style={{ textAlign: 'center', marginTop: '2em' }}>
                        {isFrench ? "Commencez à taper pour voir les résultats de recherche." : "Start typing to see search results."}
                    </Typography>
                  )
                ) : (
                    <>
                        {/* Courts with availabilities */}
                        {getFilteredCourts(dataToShow).available.map((item) => (
                            <CourtAvailabilityTable 
                                style={{ marginTop: "20px" }} 
                                data={item} 
                                key={item.id} 
                                isFrench={isFrench} 
                                analytics={analytics} 
                                setFavorites={updateFavorites} 
                                prevFavorites={favorites} 
                                setSelectedTab={setSelectedTab} 
                                isExpanded={expandedCourts.has(item.id)}
                                onExpansionChange={(isExpanded) => handleCourtExpansion(item.id, isExpanded)}
                                selectedDate={selectedDates[item.id]}
                                onDateChange={handleTabChange}
                            />
                        ))}

                        {/* Courts without availabilities */}
                        {getFilteredCourts(dataToShow).unavailable.length > 0 && (
                            <div style={{ marginTop: '2em' }}>
                                <Button
                                    onClick={() => setShowNoAvailability(!showNoAvailability)}
                                    endIcon={showNoAvailability ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    style={{ color: '#fff', marginBottom: '1em' }}
                                >
                                    {isFrench ? "Terrains sans disponibilité" : "Courts without availability"} 
                                    ({getFilteredCourts(dataToShow).unavailable.length})
                                </Button>
                                <Collapse in={showNoAvailability}>
                                    {getFilteredCourts(dataToShow).unavailable.map((item) => (
                                        <CourtAvailabilityTable 
                                            style={{ marginTop: "20px", opacity: 0.5 }} 
                                            data={item} 
                                            key={item.id} 
                                            isFrench={isFrench} 
                                            analytics={analytics} 
                                            setFavorites={updateFavorites} 
                                            prevFavorites={favorites} 
                                            setSelectedTab={setSelectedTab} 
                                            isExpanded={expandedCourts.has(item.id)}
                                            onExpansionChange={(isExpanded) => handleCourtExpansion(item.id, isExpanded)}
                                            selectedDate={selectedDates[item.id]}
                                            onDateChange={handleTabChange}
                                        />
                                    ))}
                                </Collapse>
                            </div>
                        )}
                    </>
                )}
            </>
        )}
        <FavoriteLimitDialog />
    </div>
  );

};

export default CourtAvailabilityView;