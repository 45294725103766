import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link, Tabs, Tab, Collapse, IconButton, Button, Tooltip, Popover, Dialog, DialogTitle, DialogContent, DialogActions, Box, Divider } from '@mui/material';
import { getDistance } from 'geolib';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, StarBorder as StarBorderIcon, Star as StarIcon, Close as CloseIcon, Event as EventIcon, Place as PlaceIcon, Schedule as ScheduleIcon, SportsBaseball as CourtIcon, OpenInNew as OpenInNewIcon, CallMade as CallMadeIcon } from '@mui/icons-material';
import { logEvent } from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { SvgIcon } from '@mui/material';

// Custom Google Calendar Icon
const GoogleCalendarIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M17,12.4V10.5H19V12.4M17,15.1V13.2H19V15.1M10.2,12.4V10.5H12.2V12.4M10.2,15.1V13.2H12.2V15.1M13.7,12.4V10.5H15.7V12.4M13.7,15.1V13.2H15.7V15.1M6.7,12.4V10.5H8.7V12.4M6.7,15.1V13.2H8.7V15.1M21,4H3C1.89,4 1,4.89 1,6V18A2,2 0 0,0 3,20H21A2,2 0 0,0 23,18V6C23,4.89 22.1,4 21,4M21,18H3V8H21V18Z" />
  </SvgIcon>
);

// Custom Apple Calendar Icon
const AppleCalendarIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19,4H18V2H16V4H8V2H6V4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M19,20H5V10H19V20M19,8H5V6H19V8Z" />
  </SvgIcon>
);

const StyledTabs = styled(Tabs)({
  '& .MuiTab-root': {
    color: '#fff',
    opacity: 0.7,
    '&.Mui-selected': {
      color: '#fff',
      opacity: 1
    }
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#fff'
  }
});

const times = ["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00"];

const formatDate = (dateString, isFrench, isMobile) => {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  
  const weekdays = {
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
  };
  const months = {
    en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    fr: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc']
  };
  
  const weekday = weekdays[isFrench ? 'fr' : 'en'][date.getDay()];
  const monthName = months[isFrench ? 'fr' : 'en'][date.getMonth()];
  
  return isMobile ? 
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', lineHeight: '1.2' }}>
      <div>{weekday}</div>
      <div>{monthName} {day}</div>
    </div> :
    `${weekday}, ${monthName} ${day}`;
};

const formatFullDate = (dateString, isFrench) => {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  
  const weekdays = {
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
  };
  const months = {
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    fr: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
  };
  
  const weekday = weekdays[isFrench ? 'fr' : 'en'][date.getDay()];
  const monthName = months[isFrench ? 'fr' : 'en'][date.getMonth()];
  
  return isFrench
    ? `${weekday} ${day} ${monthName} ${year}`
    : `${weekday}, ${monthName} ${day}, ${year}`;
};

const GreenOutlinedButton = styled(Button)(({ theme }) => ({
  padding: '10px 16px',
  fontSize: '0.95rem',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  color: 'green',
  borderColor: 'green',
  fontWeight: 500,
  '&:hover': {
    borderColor: 'darkgreen',
    backgroundColor: 'rgba(0, 128, 0, 0.04)'
  }
}));

const GreenContainedButton = styled(Button)(({ theme }) => ({
  padding: '10px 16px',
  fontSize: '0.95rem',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  backgroundColor: 'green',
  color: 'white',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: 'darkgreen'
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 128, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)'
  }
}));

const CalendarButton = styled(Button)(({ theme }) => ({
  padding: '8px 16px',
  fontSize: '0.9rem',
  textTransform: 'none',
  height: '40px',
  width: '100%',
  marginBottom: '8px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: 'rgba(0, 0, 0, 0.26)'
  }
}));

const translations = {
    en: {
        bookingLimitUnauth: "Create an account",
        bookingLimitUnauthDesc: "to get more bookings",
        bookingLimitAuth: "Upgrade to Premium plan for unlimited bookings",
        bookingsLeft: "bookings left",
        bookingsLeftWeek: "bookings left this week",
        calendarUpgradeText: "Upgrade to Premium plan to add events to your calendar"
    },
    fr: {
        bookingLimitUnauth: "Créez un compte",
        bookingLimitUnauthDesc: "pour obtenir plus de réservations",
        bookingLimitAuth: "Passez au plan Premium pour des réservations illimitées",
        bookingsLeft: (count) => count <= 1 ? "réservation restante" : "réservations restantes",
        bookingsLeftWeek: (count) => count <= 1 ? "réservation restante cette semaine" : "réservations restantes cette semaine",
        calendarUpgradeText: "Passez au plan Premium pour ajouter des événements à votre calendrier"
    }
};

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  '& .MuiTypography-root': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem'
    }
  }
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  }
}));

const CourtAvailabilityTable = ({ 
  data, 
  isFrench, 
  analytics, 
  setFavorites, 
  prevFavorites, 
  setSelectedTab, 
  isExpanded, 
  onExpansionChange,
  selectedDate: parentSelectedDate,
  onDateChange
}) => {
  const [selectedDate, setSelectedDate] = useState(
    parentSelectedDate || 
    (data.tableStats ? Object.keys(data.tableStats[0].availability).sort()[0] : [])
  );
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [distance, setDistance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBookingLink, setSelectedBookingLink] = useState(null);
  const [bookingBehavior, setBookingBehavior] = useState('search');
  const [directBookingsLeft, setDirectBookingsLeft] = useState(3);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [selectedBookingDetails, setSelectedBookingDetails] = useState(null);
  const [weeklyBookingsLeft, setWeeklyBookingsLeft] = useState(null);
  const [quickLinks, setQuickLinks] = useState(null);
  const navigate = useNavigate();

  const handleResize = () => {
    const isMobile = window.innerWidth < 600;
    setIsMobile(isMobile);
  };

  useEffect(() => {
    const user = firebase.auth().currentUser;
    setCurrentUser(user);
  
    if (user) {
      // Get favorites
      const docRef = firebase.firestore().collection('userFavorites').doc(user.uid);
      docRef.get().then((doc) => {
        if (doc.exists && doc.data().favorites.includes(data.siteId)) {
          setIsFavorite(true);
        }
      });

      // Get user account type and set booking behavior
      firebase.firestore().collection('users').doc(user.uid).get()
        .then((doc) => {
          if (doc.exists) {
            const accountType = doc.data().accountType || 'free';
            // For free users, always set to popup and update their preference
            if (accountType === 'free') {
              setBookingBehavior('popup');
              // Update the stored preference to popup
              firebase.firestore().collection('userPreferences').doc(user.uid)
                .set({
                  bookingBehavior: 'popup'
                }, { merge: true });
            } else {
              // For non-free users, get their preference
              firebase.firestore().collection('userPreferences').doc(user.uid).get()
                .then((prefDoc) => {
                  if (prefDoc.exists && prefDoc.data().bookingBehavior) {
                    setBookingBehavior(prefDoc.data().bookingBehavior);
                  } else {
                    setBookingBehavior('popup'); // Default to popup if no preference
                  }
                });
            }
          }
        });
    } else {
      // For non-logged in users, default to popup
      setBookingBehavior('popup');
    }
  
    // The rest of your existing useEffect code
    const timer = setTimeout(() => {
      handleResize();
      setLoading(false);
    }, 50);
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  useEffect(() => {
    calculateDistance();
  }, []);  

    const toggleFavorite = (event) => {
        event.stopPropagation();
        const user = firebase.auth().currentUser;
        if (user) {
            if (isFavorite) {
                // Remove from favorites
                const newFavorites = prevFavorites.filter(favId => favId !== parseInt(data.siteId));
                const updatedFavorites = setFavorites(newFavorites);
                setIsFavorite(updatedFavorites.includes(parseInt(data.siteId)));
            } else {
                // Add to favorites
                const newFavorites = [...prevFavorites, parseInt(data.siteId)];
                const updatedFavorites = setFavorites(newFavorites);
                setIsFavorite(updatedFavorites.includes(parseInt(data.siteId)));
            }
        } else {
            // Show the login popup if the user is not logged in
            const rect = starRef.current.getBoundingClientRect();
            const position = {
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX
            };
            setShowLoginPopup(position);
        }
    };

    const LoginPopup = ({ position, onClose }) => (
        <div style={{
            position: 'absolute', 
            top: position.top, 
            left: position.left, 
            backgroundColor: 'white', 
            padding: '20px', 
            borderRadius: '5px', 
            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', 
            zIndex: 1000
        }}>
            <Typography variant="h6" color="textSecondary">{isFrench ? "Connexion Requise" : "Login Required"}</Typography>
            <Typography variant="body1" style={{ marginBottom: '20px', marginTop:'10px' }} color="textSecondary">{isFrench ? "Vous avez besoin d'un compte pour ajouter aux favoris." : "You need an account to add to favorites."}</Typography>
            <Button style={{ marginRight: '10px' }} onClick={onClose}>{isFrench ? "Fermer" : "Close"}</Button>
            <Button variant="contained" color="primary" onClick={() => {
                setSelectedTab('login');
            }}>{isFrench ? "Connexion / Inscription" : "Login / Signup"}</Button>
            
        </div>
    );

  const starRef = useRef(null);

  const calculateDistance = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          const sitePosition = data.sitePosition;

          const distanceInMeters = getDistance(currentLocation, sitePosition);
          const distanceInKm = (distanceInMeters / 1000).toFixed(2);

          setDistance(distanceInKm);
        },
        error => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleTabChange = (event, newDate) => {
    logEvent(analytics, 'table_reservation_date_change', {
      date: newDate,
    });
    setSelectedDate(newDate);
    onDateChange && onDateChange(data.siteId, newDate);
  };

  const handleCalendarAction = (type) => {
    if (!selectedBookingDetails) return;

    const { facility, date, time } = selectedBookingDetails;
    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');
    
    // Create start and end times (1 hour duration)
    const startTime = new Date(year, month - 1, day, parseInt(hour), parseInt(minute));
    const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Add 1 hour

    // Format the dates for calendar URLs
    const formatDate = (date) => {
      return date.toISOString().replace(/-|:|\.\d+/g, '');
    };

    // Extract court number if it exists
    const courtNumber = facility.match(/\d+/)?.[0] || '';
    
    // Create title based on language
    const title = isFrench
      ? encodeURIComponent(`Tennis au ${data.siteName}${courtNumber ? ` - Terrain ${courtNumber}` : ''}`)
      : encodeURIComponent(`Tennis at ${data.siteName}${courtNumber ? ` - Court ${courtNumber}` : ''}`);

    const location = encodeURIComponent(data.siteAddress);
    const details = isFrench
      ? encodeURIComponent(`Terrain: ${facility}\nAdresse: ${data.siteAddress}\nTéléphone: ${data.sitePhone}`)
      : encodeURIComponent(`Court: ${facility}\nAddress: ${data.siteAddress}\nPhone: ${data.sitePhone}`);

    if (type === 'google') {
      const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${formatDate(startTime)}/${formatDate(endTime)}&details=${details}&location=${location}`;
      window.open(url, '_blank');
    } else if (type === 'apple') {
      const url = `data:text/calendar;charset=utf-8,BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
URL:${location}
DTSTART:${formatDate(startTime)}
DTEND:${formatDate(endTime)}
SUMMARY:${title}
DESCRIPTION:${details}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`;
      
      const link = document.createElement('a');
      link.href = url;
      link.download = 'tennis_booking.ics';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    logEvent(analytics, 'calendar_add', {
      type: type,
      facility: facility,
      date: date,
      time: time
    });
  };

  const handleBookClick = (event, link) => {
    event.preventDefault();
    event.stopPropagation();

    if (!quickLinks) {
      console.error('Quick links not loaded yet');
      return;
    }

    const actualSearchLink = quickLinks[link.searchLinkKey];
    const actualBookingLink = quickLinks[link.bookingLinkKey];

    if (!actualSearchLink || !actualBookingLink) {
      console.error('Links not found in quickLinks');
      return;
    }

    // Set the booking details for the calendar modal
    setSelectedBookingDetails({
      facility: link.facility,
      date: link.date,
      time: link.time,
      searchLink: actualSearchLink,
      bookingLink: actualBookingLink
    });

    logEvent(analytics, 'table_reservation_click', {
      facility: link.facility,
      time: link.time,
      date: link.date,
      link: actualBookingLink,
      behavior: bookingBehavior
    });

    // Always show the modal for free users
    if (currentUser) {
      firebase.firestore().collection('users').doc(currentUser.uid).get()
        .then((doc) => {
          if (doc.exists) {
            const accountType = doc.data().accountType || 'free';
            if (accountType === 'free') {
              setShowCalendarModal(true);
              return;
            }
            
            // For non-free users, follow their preference
            setShowCalendarModal(true);
            if (bookingBehavior === 'direct') {
              handleDirectBooking(actualBookingLink);
            } else if (bookingBehavior === 'search') {
              window.open(actualSearchLink, '_blank');
            }
          }
        });
    } else {
      // For non-logged in users, always show popup
      setShowCalendarModal(true);
    }
  };

  const handleDirectBooking = (bookingLink) => {
    if (!bookingLink) {
      console.error('Booking link not found');
      return;
    }

    if (!currentUser) {
      if (directBookingsLeft > 0) {
        const newCount = directBookingsLeft - 1;
        localStorage.setItem('directBookingsLeft', newCount.toString());
        setDirectBookingsLeft(newCount);
        window.open(bookingLink, '_blank');
      } else {
        setSelectedTab('login');
        setShowCalendarModal(false);
      }
    } else {
      if (weeklyBookingsLeft === null) {
        // User has standard plan - unlimited bookings
        window.open(bookingLink, '_blank');
      } else if (weeklyBookingsLeft > 0) {
        const newCount = weeklyBookingsLeft - 1;
        localStorage.setItem('weeklyBookingsLeft', newCount.toString());
        setWeeklyBookingsLeft(newCount);
        window.open(bookingLink, '_blank');
      } else {
        // Show upgrade message or redirect to profile
        setSelectedTab('profile');
        setShowCalendarModal(false);
      }
    }
  };

  const getLink = (facility, time, date) => {
    const { availability } = facility;
    const slots = availability[date];
    const slot = slots && slots.find(s => s.startTime === time);
    
    if (slot) {
      const searchLinkKey = facility.searchLinks[date][time] || facility.searchLinks[date]['all'];
      const linkData = {
        facility: facility.facilityName,
        time: time,
        date: date,
        searchLinkKey: searchLinkKey,
        bookingLinkKey: slot.linkKey
      };

      return (
        <>
          <Link 
            onClick={(e) => handleBookClick(e, linkData)}
            style={{ 
              fontWeight: 'bold', 
              color: 'green',
              textDecoration: 'none',
              cursor: 'pointer'
            }}
          >
            {isFrench ? (isMobile ? "Rés." : "Réserver") : "Book"}
          </Link>
        </>
      );
    } else {
      return (
        <Typography variant="body2" color="textSecondary">
          {isMobile ? "N/A" : isFrench ? "Non disponible" : "Not available"}
        </Typography>
      );
    }
  };

  const onCellMouseOver = (time, facilityId) => {
    setHoveredRow(time);
    setHoveredCell(facilityId);
  };

  const extractNumber = (name) => {
    const matches = name.match(/\d+/);
    return matches ? parseInt(matches[0]) : 0;
  };

  // add a function to extract tennis or pickleball from the name or any similar name. Return T if tennis, P if pickleball, TC if both are in the name, and empty string if neither
  const extractSport = (name) => {
    name = name.toLowerCase();
  
    if (name.includes("tennis") && name.includes("pickleball")) {
      return "TC"; // Both tennis and pickleball are in the name
    } else if (name.includes("tennis")) {
      return "T"; // Tennis is in the name
    } else if (name.includes("pickleball")) {
      return "P"; // Pickleball is in the name
    } else {
      return ""; // Neither tennis nor pickleball is in the name
    }
  };

  const sortedTableStats = data.tableStats ? [...data.tableStats].sort((a, b) => {
    const nameA = a.facilityName.toUpperCase();
    const nameB = b.facilityName.toUpperCase();

    const numberA = extractNumber(nameA);
    const numberB = extractNumber(nameB);

    if (numberA !== numberB) {
      return numberA - numberB;
    }

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }) : [];

  // i want the sorted Dates to be in order of the dates, but using all of tableStats entries since some will have dates that others don't
  const availabilityDates = []; 
  // extract keys from data.tableStats and add them to availabilityDates if they aren't already there
  sortedTableStats.forEach((facility) => {
    Object.keys(facility.availability).forEach((date) => {
      if (!availabilityDates.includes(date)) {
        availabilityDates.push(date);
      }
    });
  });

  const sortedDates = availabilityDates.sort();

  const toggleExpanded = () => {
    logEvent(analytics, 'table_reservation_expand', {
      facility: data.siteName,
      expanded: !isExpanded
    });
    onExpansionChange(!isExpanded);
  };

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (user) {
      firebase.firestore().collection('userPreferences').doc(user.uid).get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            setBookingBehavior(data.bookingBehavior || 'popup');
          }
        });
      setDirectBookingsLeft(null); // Reset for logged in users
    } else {
      // For non-logged in users, get remaining bookings from localStorage
      const remaining = localStorage.getItem('directBookingsLeft');
      setDirectBookingsLeft(remaining === null ? 3 : parseInt(remaining));
    }
  }, [currentUser]);

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (user) {
      // For logged in users, check weekly bookings from localStorage
      const currentWeek = getWeekNumber(new Date());
      const storedWeek = localStorage.getItem('bookingWeek');
      const storedCount = localStorage.getItem('weeklyBookingsLeft');
      
      if (storedWeek !== currentWeek.toString()) {
        // Reset count for new week
        localStorage.setItem('bookingWeek', currentWeek);
        localStorage.setItem('weeklyBookingsLeft', '3');
        setWeeklyBookingsLeft(3);
      } else {
        setWeeklyBookingsLeft(storedCount === null ? 3 : parseInt(storedCount));
      }

      // Check user's subscription status
      firebase.firestore().collection('users').doc(user.uid).get()
          .then((doc) => {
              if (doc.exists) {
                  const userData = doc.data();
                  if (userData.accountType === 'standard') {
                      setWeeklyBookingsLeft(null); // Unlimited bookings for standard users
                  }
              }
          });
    } else {
      // For non-logged in users, check monthly bookings from localStorage
      const currentMonth = new Date().getMonth();
      const storedMonth = localStorage.getItem('bookingMonth');
      const storedCount = localStorage.getItem('directBookingsLeft');
      
      if (storedMonth !== currentMonth.toString()) {
          // Reset count for new month
          localStorage.setItem('bookingMonth', currentMonth);
          localStorage.setItem('directBookingsLeft', '3');
          setDirectBookingsLeft(3);
      } else {
          setDirectBookingsLeft(storedCount === null ? 3 : parseInt(storedCount));
      }
    }
  }, [currentUser]);

  const getWeekNumber = (date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  };

  useEffect(() => {
    // Subscribe to quickLinks document
    const unsubscribe = firebase.firestore()
      .collection('overview')
      .doc('quickLinks')
      .onSnapshot((doc) => {
        if (doc.exists) {
          setQuickLinks(doc.data().quickLinks);
        }
      });

    return () => unsubscribe();
  }, []);

  // Add effect to sync with parent's selected date
  useEffect(() => {
    if (parentSelectedDate && parentSelectedDate !== selectedDate) {
      setSelectedDate(parentSelectedDate);
    }
  }, [parentSelectedDate]);

  return (
    <div style={{ marginTop: "1em", marginBottom: "2em" }}>
      {showLoginPopup && <LoginPopup position={showLoginPopup} onClose={() => setShowLoginPopup(null)} />}
      <span style={{ cursor: 'pointer' }} onClick={toggleExpanded}>
        <Typography variant={isMobile ? "h6" : "h4"} component="h2" align="center" sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          gap: '4px',
          flexWrap: 'wrap'
        }}>
          <span style={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: '8px',
            fontSize: isMobile ? '1.1rem' : 'inherit'
          }}>
            {!loading && (
              <Tooltip title={isFrench ? "Ajouter aux favoris" : "Add to favorites"}>
                <IconButton 
                  ref={starRef} 
                  onClick={toggleFavorite}
                  size={isMobile ? "small" : "medium"}
                  sx={{ 
                    padding: isMobile ? '4px' : '8px',
                    marginRight: '-4px'  // compensate for the icon padding
                  }}
                >
                  {isFavorite ? <StarIcon style={{color: "yellow", fontSize: isMobile ? '20px' : '24px'}} /> : <StarBorderIcon style={{color: "white", fontSize: isMobile ? '20px' : '24px'}} />}
                </IconButton>
              </Tooltip>
            )}
            {loading ? "" : data.siteName}
            <small style={{ 
              whiteSpace: 'nowrap',
              fontSize: isMobile ? '0.8rem' : '0.9rem',
              opacity: 0.9
            }}>{loading ? "" : data.distanceInKm && `~${data.distanceInKm} km`}</small>
            {loading ? "" : isExpanded ? 
              <ExpandLessIcon style={{ fontSize: isMobile ? '20px' : '24px', color: '#fff' }} /> : 
              <ExpandMoreIcon style={{ fontSize: isMobile ? '20px' : '24px', color: '#fff' }} />
            }
          </span>
        </Typography>
      </span>
      <Collapse in={isExpanded}>
        <div>
          <Typography 
            variant={isMobile ? "body2" : "body1"} 
            sx={{ 
              fontSize: isMobile ? '0.85rem' : '1rem',
              opacity: 0.9,
              mt: 1
            }}
          >
            {loading ? "" : data.siteAddress} (
            <Link 
              target="_blank" 
              onClick={(event)=> event.stopPropagation()} 
              style={{ 
                color: '#64b5f6',
                fontSize: 'inherit'
              }} 
              href={loading ? "" : data.siteGoogleMapsLink}
            >
              {isFrench ? "Carte" : "Map"}
            </Link>)
          </Typography>
          <Typography 
            variant={isMobile ? "body2" : "body1"}
            sx={{ 
              fontSize: isMobile ? '0.85rem' : '1rem',
              opacity: 0.9,
              mb: 1
            }}
          >
            {loading ? "" : data.sitePhone}
          </Typography>

          {!loading && (
            <StyledTabs
              value={selectedDate}
              onChange={handleTabChange}
              centered
              variant="fullWidth"
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              {sortedDates.map(date => (
                <Tab 
                  key={date} 
                  label={formatDate(date, isFrench, isMobile)} 
                  value={date} 
                  style={{ 
                    minWidth: '0', 
                    fontSize: isMobile ? '0.7em' : '1em',
                    padding: isMobile ? '6px 8px' : '12px 16px'
                  }} 
                />
              ))}
            </StyledTabs>
          )}

          {!loading && (
            <TableContainer style={{maxHeight:"90vh"}} component={Paper}>
              {sortedTableStats.length === 0 ? (
                <Typography 
                  variant={isMobile ? "body2" : "body1"} 
                  sx={{ 
                    padding: 3,
                    textAlign: 'center',
                    color: 'textSecondary',
                    opacity: 0.9,
                    fontSize: isMobile ? '0.85rem' : '1rem'
                  }}
                >
                  {isFrench ? "Aucun terrain disponible" : "No courts available"}
                </Typography>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="stickyColumn">{isFrench ? "Heure" : "Time"}</TableCell>
                      {sortedTableStats.map(t => (
                        <TableCell style={{ backgroundColor: '#eee' }} key={t.facilityId} className="stickyHeader">
                          {isMobile ? extractSport(t.facilityName)+"#" + extractNumber(t.facilityName) : t.facilityName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {times.map(time => (
                      <TableRow
                        key={time}
                        onMouseOver={() => onCellMouseOver(time, null)}
                        onMouseOut={() => onCellMouseOver(null, null)}
                        style={{ backgroundColor: hoveredRow === time ? '#eee' : 'transparent' }}
                      >
                        <TableCell style={{ backgroundColor: '#eee' }} className="stickyColumn">{time}</TableCell>
                        {sortedTableStats.map(facility => {
                          const link = getLink(facility, time, selectedDate);
                          const isAvailable = link.props.href;

                          return (
                            <TableCell
                              key={facility.facilityId}
                              onClick={(event) => {
                                if (isAvailable) {
                                  event.stopPropagation();
                                }
                              }}
                              onMouseOver={() => onCellMouseOver(time, facility.facilityId)}
                              style={{
                                backgroundColor: hoveredRow === time && hoveredCell === facility.facilityId ? '#ddd' : 'transparent',
                                cursor: isAvailable ? 'pointer' : 'default'
                              }}
                            >
                              {link}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )}
        </div>
      </Collapse>

      <Dialog 
        open={showCalendarModal} 
        onClose={() => setShowCalendarModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle
          sx={{
            fontSize: { xs: '1rem', sm: '1.5rem' }
          }}
        >
          {isFrench ? "Réservation" : "Booking"}
          <IconButton
            aria-label="close"
            onClick={() => setShowCalendarModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          {selectedBookingDetails && (
            <>
              <Typography 
                variant="h5" 
                sx={{ 
                  mt: { xs: 1.5, sm: 1.5 },
                  mb: { xs: 2, sm: 2.5 },
                  fontWeight: 600,
                  color: 'primary.main',
                  textAlign: 'center',
                  fontSize: { xs: '1.2rem', sm: '1.5rem' }
                }}
              >
                {data.siteName}
              </Typography>

              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: { xs: 1.5, sm: 2.5 }
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1.5, sm: 2 } }}>
                  <CourtIcon color="action" sx={{ fontSize: { xs: 24, sm: 28 } }} />
                  <Typography variant="body1" sx={{ 
                    fontSize: { xs: '0.95rem', sm: '1.1rem' }
                  }}>
                    {selectedBookingDetails.facility}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1.5, sm: 2 } }}>
                  <PlaceIcon color="action" sx={{ fontSize: { xs: 24, sm: 28 } }} />
                  <Typography variant="body1" sx={{ 
                    fontSize: { xs: '0.90rem', sm: '1.1rem' }
                  }}>
                    {data.siteAddress}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1.5, sm: 2 } }}>
                  <EventIcon color="action" sx={{ fontSize: { xs: 24, sm: 28 } }} />
                  <Typography variant="body1" sx={{ 
                    fontSize: { xs: '0.95rem', sm: '1.1rem' }
                  }}>
                    {formatFullDate(selectedBookingDetails.date, isFrench)}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1.5, sm: 2 } }}>
                  <ScheduleIcon color="action" sx={{ fontSize: { xs: 24, sm: 28 } }} />
                  <Typography variant="body1" sx={{ 
                    fontSize: { xs: '0.95rem', sm: '1.1rem' }
                  }}>
                    {selectedBookingDetails.time}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: { xs: 2.5, sm: 4 }, mb: { xs: 2, sm: 3 } }}>
                {(bookingBehavior === 'popup' || bookingBehavior === 'search') && (
                  <GreenOutlinedButton
                    fullWidth
                    onClick={() => window.open(selectedBookingDetails.searchLink, '_blank')}
                    sx={{ 
                      mb: { xs: 1.5, sm: 2 },
                      height: { xs: '40px', sm: '44px' },
                      fontSize: { xs: '0.9rem', sm: '0.95rem' },
                      borderWidth: '1.5px',
                      '&:hover': {
                        borderWidth: '1.5px'
                      }
                    }}
                    startIcon={<CallMadeIcon />}
                  >
                    {isFrench ? "Voir les résultats de recherche" : "View Search Results"}
                  </GreenOutlinedButton>
                )}

                {(bookingBehavior === 'popup' || bookingBehavior === 'direct') && (
                  <GreenContainedButton
                    fullWidth
                    onClick={() => handleDirectBooking(selectedBookingDetails.bookingLink)}
                    disabled={(!currentUser && directBookingsLeft === 0) || (currentUser && weeklyBookingsLeft === 0)}
                    sx={{ 
                      mb: { xs: 1.5, sm: 2 },
                      height: { xs: '40px', sm: '44px' },
                      boxShadow: 1,
                      '&:hover': {
                        boxShadow: 2
                      }
                    }}
                    startIcon={<OpenInNewIcon />}
                  >
                    {isFrench ? "Réserver directement" : "Book Directly"}
                  </GreenContainedButton>
                )}

                {!currentUser && directBookingsLeft !== null && (
                  <Typography 
                    variant="body2" 
                    color="textSecondary" 
                    sx={{ 
                      mt: { xs: 1.5, sm: 2 }, 
                      textAlign: 'center',
                      fontSize: { xs: '0.8rem', sm: '0.875rem' }
                    }}
                  >
                    {directBookingsLeft} {isFrench 
                      ? translations.fr.bookingsLeft(directBookingsLeft)
                      : translations.en.bookingsLeft}
                    <br />
                    <Link
                      component="button"
                      onClick={() => {
                        navigate('/login');
                        setShowCalendarModal(false);
                      }}
                      sx={{ 
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {translations[isFrench ? 'fr' : 'en'].bookingLimitUnauth}
                    </Link>
                    {' '}
                    {translations[isFrench ? 'fr' : 'en'].bookingLimitUnauthDesc}
                  </Typography>
                )}

                {currentUser && weeklyBookingsLeft !== null && (
                  <Typography 
                    variant="body2" 
                    color="textSecondary" 
                    sx={{ 
                      mt: { xs: 1.5, sm: 2 }, 
                      textAlign: 'center',
                      fontSize: { xs: '0.8rem', sm: '0.875rem' }
                    }}
                  >
                    {weeklyBookingsLeft} {isFrench 
                      ? translations.fr.bookingsLeftWeek(weeklyBookingsLeft)
                      : translations.en.bookingsLeftWeek}
                    <br />
                    <Link
                      component="button"
                      onClick={() => {
                        navigate('/profile');
                        setShowCalendarModal(false);
                      }}
                      sx={{ 
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {translations[isFrench ? 'fr' : 'en'].bookingLimitAuth}
                    </Link>
                  </Typography>
                )}
              </Box>

              <Divider sx={{ mb: { xs: 2, sm: 3 } }} />

              <Box sx={{ position: 'relative' }}>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    mb: { xs: 1.5, sm: 2 }, 
                    textAlign: 'center', 
                    color: 'text.secondary',
                    fontSize: { xs: '0.9rem', sm: '1rem' }
                  }}
                >
                  {isFrench ? "Ajouter au calendrier" : "Add to Calendar"}
                </Typography>

                <Box>
                  <CalendarButton
                    variant="outlined"
                    onClick={() => handleCalendarAction('google')}
                    startIcon={<GoogleCalendarIcon />}
                    sx={{ 
                      fontSize: { xs: '0.9rem', sm: '1rem' },
                      height: { xs: '36px', sm: '40px' },
                      mb: { xs: 1, sm: 1.5 }
                    }}
                    disabled={!currentUser || (currentUser && weeklyBookingsLeft !== null)}
                  >
                    {isFrench ? "Ajouter à Google Calendar" : "Add to Google Calendar"}
                  </CalendarButton>
                  <CalendarButton
                    variant="outlined"
                    onClick={() => handleCalendarAction('apple')}
                    startIcon={<AppleCalendarIcon />}
                    sx={{ 
                      fontSize: { xs: '0.9rem', sm: '1rem' },
                      height: { xs: '36px', sm: '40px' }
                    }}
                    disabled={!currentUser || (currentUser && weeklyBookingsLeft !== null)}
                  >
                    {isFrench ? "Ajouter à Apple Calendar" : "Add to Apple Calendar"}
                  </CalendarButton>
                </Box>

                {(!currentUser || (currentUser && weeklyBookingsLeft !== null)) && (
                  <Typography 
                    variant="body2" 
                    color="textSecondary" 
                    sx={{ 
                      mt: { xs: 1.5, sm: 2 }, 
                      textAlign: 'center',
                      fontSize: { xs: '0.8rem', sm: '0.875rem' }
                    }}
                  >
                    <Link
                      component="button"
                      onClick={() => {
                        navigate('/profile');
                        setShowCalendarModal(false);
                      }}
                      sx={{ 
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {translations[isFrench ? 'fr' : 'en'].calendarUpgradeText}
                    </Link>
                  </Typography>
                )}
              </Box>
            </>
          )}
        </StyledDialogContent>
      </Dialog>
    </div>
  );
};

export default CourtAvailabilityTable;
