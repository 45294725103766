import React, { useState, useEffect } from 'react';
import {
    Container,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    CircularProgress,
    Alert,
    Snackbar,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Chip,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    DialogContentText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const translations = {
    en: {
        title: "Admin Dashboard",
        users: "Users",
        search: "Search",
        refresh: "Refresh",
        loading: "Loading...",
        noUsers: "No users found",
        columns: {
            email: "Email",
            name: "Name",
            plan: "Plan",
            status: "Status",
            actions: "Actions"
        },
        grantStandard: "Give Premium VIP",
        grantPro: "Give Pro VIP",
        grantDialog: {
            title: "Give VIP Access",
            duration: "Duration (months)",
            confirm: "Confirm",
            cancel: "Cancel",
            standardTitle: "Give Premium VIP Access",
            proTitle: "Give Pro VIP Access"
        },
        success: "Successfully granted VIP access",
        error: "An error occurred",
        unauthorized: "Unauthorized access",
        cancelStandard: "Cancel VIP Access",
        cancelDialog: {
            title: "Cancel VIP Access",
            message: "Are you sure you want to cancel this user's VIP access?",
            confirm: "Confirm",
            cancel: "Cancel"
        },
        cancelSuccess: "Successfully cancelled VIP access"
    },
    fr: {
        title: "Tableau de bord admin",
        users: "Utilisateurs",
        search: "Rechercher",
        refresh: "Actualiser",
        loading: "Chargement...",
        noUsers: "Aucun utilisateur trouvé",
        columns: {
            email: "Courriel",
            name: "Nom",
            plan: "Forfait",
            status: "Statut",
            actions: "Actions"
        },
        grantStandard: "Donner VIP Premium",
        grantPro: "Donner VIP Pro",
        grantDialog: {
            title: "Donner accès VIP",
            duration: "Durée (mois)",
            confirm: "Confirmer",
            cancel: "Annuler",
            standardTitle: "Donner accès VIP Premium",
            proTitle: "Donner accès VIP Pro"
        },
        success: "Accès VIP accordé avec succès",
        error: "Une erreur est survenue",
        unauthorized: "Accès non autorisé",
        cancelStandard: "Annuler accès VIP",
        cancelDialog: {
            title: "Annuler accès VIP",
            message: "Êtes-vous sûr de vouloir annuler l'accès VIP de cet utilisateur ?",
            confirm: "Confirmer",
            cancel: "Annuler"
        },
        cancelSuccess: "Accès VIP annulé avec succès"
    }
};

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
}));

const SearchBar = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
}));

export default function AdminPage({ isFrench }) {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [grantDialogOpen, setGrantDialogOpen] = useState(false);
    const [duration, setDuration] = useState(12);
    const [isAdmin, setIsAdmin] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [selectedPlanType, setSelectedPlanType] = useState('standard');

    const t = isFrench ? translations.fr : translations.en;

    useEffect(() => {
        checkAdminStatus();
    }, []);

    const checkAdminStatus = async () => {
        const user = firebase.auth().currentUser;
        if (!user) {
            setError(t.unauthorized);
            setLoading(false);
            return;
        }

        const doc = await firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .get();

        if (!doc.exists || !doc.data().isAdmin) {
            setError(t.unauthorized);
            setLoading(false);
            return;
        }

        setIsAdmin(true);
        fetchUsers();
    };

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const usersSnapshot = await firebase.firestore()
                .collection('users')
                .get();

            const fetchedUsers = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                email: doc.data().email || '',
                displayName: doc.data().displayName || '',
                accountType: doc.data().accountType || 'free',
                subscriptionStatus: doc.data().subscriptionStatus || null
            }));

            setUsers(fetchedUsers);
        } catch (err) {
            console.error('Error fetching users:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGrantVIP = async () => {
        try {
            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + duration);

            await firebase.firestore()
                .collection('users')
                .doc(selectedUser.id)
                .update({
                    accountType: selectedPlanType,
                    subscriptionStatus: 'active',
                    subscriptionEndDate: endDate,
                    subscriptionCancelAtPeriodEnd: false,
                    isAdminGranted: true
                });

            setError({ severity: 'success', message: t.success });
            fetchUsers();
        } catch (err) {
            console.error('Error granting VIP access:', err);
            setError({ severity: 'error', message: err.message });
        } finally {
            setGrantDialogOpen(false);
            setSelectedUser(null);
        }
    };

    const handleCancelStandard = async () => {
        try {
            await firebase.firestore()
                .collection('users')
                .doc(selectedUser.id)
                .update({
                    accountType: 'free',
                    subscriptionStatus: null,
                    subscriptionEndDate: null,
                    subscriptionCancelAtPeriodEnd: false,
                    isAdminGranted: false
                });

            setError({ severity: 'success', message: t.cancelSuccess });
            fetchUsers();
        } catch (err) {
            console.error('Error cancelling standard plan:', err);
            setError({ severity: 'error', message: err.message });
        } finally {
            setCancelDialogOpen(false);
            setSelectedUser(null);
        }
    };

    const filteredUsers = users.filter(user => 
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!isAdmin) {
        return (
            <StyledContainer>
                <Alert severity="error">{t.unauthorized}</Alert>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            <Typography variant="h4" gutterBottom>
                {t.title}
            </Typography>

            <SearchBar>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={t.search}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: <SearchIcon color="action" />
                    }}
                />
                <Button
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    onClick={fetchUsers}
                >
                    {t.refresh}
                </Button>
            </SearchBar>

            {loading ? (
                <CircularProgress />
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t.columns.email}</TableCell>
                                <TableCell>{t.columns.name}</TableCell>
                                <TableCell>{t.columns.plan}</TableCell>
                                <TableCell>{t.columns.status}</TableCell>
                                <TableCell>{t.columns.actions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.displayName || '-'}</TableCell>
                                    <TableCell>
                                        <Chip 
                                            label={user.accountType || 'free'}
                                            color={user.accountType === 'pro' ? 'secondary' : 
                                                  user.accountType === 'standard' ? 'primary' : 'default'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {user.subscriptionStatus && (
                                            <Chip 
                                                label={user.subscriptionStatus}
                                                color={user.subscriptionStatus === 'active' ? 'success' : 'error'}
                                                variant="outlined"
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {user.isAdminGranted && (user.accountType === 'standard' || user.accountType === 'pro') ? (
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => {
                                                    setSelectedUser(user);
                                                    setCancelDialogOpen(true);
                                                }}
                                            >
                                                {t.cancelStandard}
                                            </Button>
                                        ) : (
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setSelectedUser(user);
                                                        setSelectedPlanType('standard');
                                                        setGrantDialogOpen(true);
                                                    }}
                                                >
                                                    {t.grantStandard}
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setSelectedUser(user);
                                                        setSelectedPlanType('pro');
                                                        setGrantDialogOpen(true);
                                                    }}
                                                >
                                                    {t.grantPro}
                                                </Button>
                                            </Box>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Dialog open={grantDialogOpen} onClose={() => setGrantDialogOpen(false)}>
                <DialogTitle>
                    {selectedPlanType === 'pro' ? t.grantDialog.proTitle : t.grantDialog.standardTitle}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>{t.grantDialog.duration}</InputLabel>
                        <Select
                            value={duration}
                            label={t.grantDialog.duration}
                            onChange={(e) => setDuration(e.target.value)}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setGrantDialogOpen(false)}>
                        {t.grantDialog.cancel}
                    </Button>
                    <Button onClick={handleGrantVIP} variant="contained" color="primary">
                        {t.grantDialog.confirm}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={cancelDialogOpen} onClose={() => setCancelDialogOpen(false)}>
                <DialogTitle>{t.cancelDialog.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t.cancelDialog.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCancelDialogOpen(false)}>
                        {t.cancelDialog.cancel}
                    </Button>
                    <Button onClick={handleCancelStandard} variant="contained" color="error">
                        {t.cancelDialog.confirm}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => setError(null)}
            >
                <Alert
                    onClose={() => setError(null)}
                    severity={error?.severity || 'error'}
                >
                    {error?.message || error}
                </Alert>
            </Snackbar>
        </StyledContainer>
    );
} 