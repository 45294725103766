import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    CardContent,
    CardActions,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Chip,
    Link,
    IconButton,
    CircularProgress,
    Alert,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CloseIcon from '@mui/icons-material/Close';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY, STRIPE_STANDARD_MONTHLY_PRICE_ID, STRIPE_STANDARD_YEARLY_PRICE_ID, STRIPE_PRO_MONTHLY_PRICE_ID, STRIPE_PRO_YEARLY_PRICE_ID, STRIPE_CLOUD_FUNCTION_BASE_URL } from '../config';

const translations = {
    en: {
        title: "Subscription Plans",
        monthly: "Monthly",
        yearly: "Yearly",
        free: "Free",
        standard: "Premium",
        pro: "Pro",
        current: "Current Plan",
        upgrade: "Upgrade",
        downgrade: "Downgrade",
        comingSoon: "Coming Soon",
        monthSuffix: "/month",
        yearSuffix: "/month",
        billedAnnually: "billed annually",
        introductoryPrice: "Introductory Price",
        regularPrice: "Regular Price",
        lockInOffer: "Lock in this price before April 30th",
        freePlan: {
            title: "Basic Features",
            features: [
                "Quick court search",
                "Grid view for courts and times",
                "5 direct bookings per week",
                "Link to search results",
                "Save 1 court as favorite",
                "Basic Map view",
                "Geolocation for nearest courts",
                "Remember the language used",
                "Regular refresh cycle (30 min+)"
            ]
        },
        standardPlan: {
            title: "Enhanced Experience",
            features: [
                "All Free features, plus:",
                "Add to Google and Apple calendar",
                "Unlimited Quick book and control your booking",
                "Save unlimited courts as favorites",
                "Advanced Map view (Custom markers)",
                "Fast refresh cycle (< 10 min)",
                "Free year for every <coffee>7 coffees donated</coffee> <strike>10 coffees donated</strike>"
            ]
        },
        proPlan: {
            title: "Pro Features",
            features: [
                "All Premium features, plus:",
                "Personalized custom view",
                "Combined time and court filters",
                "Custom court availability alerts",
                "SMS/Email notifications",
                "First access to new features"
            ]
        },
        yearlySavings: "-30%",
        alwaysFree: "Always Free",
        downgradeConfirmTitle: "Confirm Downgrade",
        downgradeConfirmMessage: "Your subscription will remain active until the end of your current billing period. After that, your plan will be changed to",
        downgradeConfirmButton: "Confirm Downgrade",
        downgradeSuccessMessage: "Your subscription will be downgraded at the end of the billing period",
        cancel: "Cancel",
        remainingTime: "Remaining time on current plan:",
        days: "days",
        currentPlanEnds: "Ends",
        scheduledDowngrade: "→ Free plan",
        manageSubscription: "Manage Subscription",
        manageSubscriptionError: "Failed to access subscription management",
        standardGranted: "Premium (VIP)",
        proGranted: "Pro (VIP)",
        grantedPlan: "You have VIP access to this plan",
        subscriptionActive: "Active",
        subscriptionCanceled: "Canceled",
        renewsOn: "Renews on",
        endsOn: "Ends on",
        expiresOn: "VIP access until"
    },
    fr: {
        title: "Plans d'abonnement",
        monthly: "Mensuel",
        yearly: "Annuel",
        free: "Gratuit",
        standard: "Premium",
        pro: "Pro",
        current: "Plan actuel",
        upgrade: "Sélectionner",
        downgrade: "Rétrograder",
        comingSoon: "À venir",
        monthSuffix: "/mois",
        yearSuffix: "/mois",
        billedAnnually: "facturé annuellement",
        introductoryPrice: "Prix de lancement",
        regularPrice: "Prix régulier",
        lockInOffer: "Bloquez ce prix avant le 30 avril",
        freePlan: {
            title: "Fonctionnalités de base",
            features: [
                "Recherche rapide de terrains",
                "Vue en grille des terrains et horaires",
                "5 réservations directes par semaine",
                "Lien vers les résultats de recherche",
                "Sauvegarder 1 terrain favori",
                "Vue carte basique",
                "Géolocalisation des terrains",
                "Souvenir du langage utilisé",
                "Cycle de rafraîchissement régulier (30 min+)"
            ]
        },
        standardPlan: {
            title: "Expérience améliorée",
            features: [
                "Toutes les fonctionnalités Gratuites, plus :",
                "Ajout à Google et Apple calendar",
                "Réservation directe illimitée et contrôle de vos réservations",
                "Sauvegarde illimitée des terrains favoris",
                "Vue carte avancée (marqueurs personnalisés)",    
                "Cycle de rafraîchissement rapide (< 10 min)",
                "Un an gratuit pour chaque <coffee>7 cafés offerts</coffee> <strike>10 cafés offerts</strike>"
            ]
        },
        proPlan: {
            title: "Fonctionnalités Pro",
            features: [
                "Toutes les fonctionnalités Premium, plus :",
                "Vue personnalisée",
                "Combinaison de filtres de temps et de terrains",
                "Alertes de disponibilité",
                "Notifications par texto/courriel",
                "Premier accès aux nouvelles fonctionnalités"
            ]
        },
        yearlySavings: "-30%",
        alwaysFree: "Toujours Gratuit",
        downgradeConfirmTitle: "Confirmer la rétrogradation",
        downgradeConfirmMessage: "Votre abonnement restera actif jusqu'à la fin de votre période de facturation actuelle. Après cela, votre forfait passera à",
        downgradeConfirmButton: "Confirmer la rétrogradation",
        downgradeSuccessMessage: "Votre abonnement sera rétrogradé à la fin de la période de facturation",
        cancel: "Annuler",
        remainingTime: "Temps restant sur le forfait actuel :",
        days: "jours",
        currentPlanEnds: "Fin",
        scheduledDowngrade: "→ Forfait gratuit",
        manageSubscription: "Gérer l'abonnement",
        manageSubscriptionError: "Impossible d'accéder à la gestion de l'abonnement",
        standardGranted: "Premium (VIP)",
        proGranted: "Pro (VIP)",
        grantedPlan: "Vous avez un accès VIP à ce forfait",
        subscriptionActive: "Actif",
        subscriptionCanceled: "Annulé",
        renewsOn: "Se renouvelle le",
        endsOn: "Se termine le",
        expiresOn: "Accès VIP jusqu'au"
    }
};

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const ModalContent = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    maxWidth: '1000px',
    width: '90%',
    maxHeight: '90vh',
    overflow: 'auto',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        width: '95%',
        margin: theme.spacing(2),
        maxHeight: '85vh'
    }
}));

const PlanCard = styled(Card)(({ theme, isCurrentPlan }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    border: isCurrentPlan ? `2px solid ${theme.palette.primary.main}` : 'none',
    '&:hover': {
        transform: 'translateY(-4px)',
        transition: 'transform 0.3s ease'
    },
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        minHeight: '65vh'
    }
}));

const PlansContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gap: theme.spacing(2)
    }
}));

const StyledBadge = styled('span')(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: 'white',
    padding: '1px 4px',
    borderRadius: '4px',
    fontSize: '0.6rem',
    position: 'absolute',
    top: -5,
    right: -5,
    transform: 'rotate(20deg)',
    whiteSpace: 'nowrap',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    fontWeight: 'bold'
}));

const ComingSoonOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1,
    borderRadius: theme.spacing(1),
    pointerEvents: 'none',
}));

// Stripe price IDs configuration
const STRIPE_PRICE_IDS = {
    standard: {
        monthly: STRIPE_STANDARD_MONTHLY_PRICE_ID,
        yearly: STRIPE_STANDARD_YEARLY_PRICE_ID
    },
    pro: {
        monthly: STRIPE_PRO_MONTHLY_PRICE_ID,
        yearly: STRIPE_PRO_YEARLY_PRICE_ID
    }
};

// Plan level mapping
const getPlanLevel = (planName) => {
    const levels = {
        'free': 0,
        'standard': 1,
        'pro': 2
    };
    return levels[planName] || 0;
};

export default function SubscriptionModal({ open, onClose, currentPlan: initialPlan, isFrench }) {
    const [billingCycle, setBillingCycle] = useState('yearly');
    const [currentPlan, setCurrentPlan] = useState(initialPlan);
    const [loadingPlan, setLoadingPlan] = useState(null); // Track which plan is loading
    const [loadingManage, setLoadingManage] = useState(false); // Track loading state for manage subscription
    const [error, setError] = useState(null);
    const [downgradeTarget, setDowngradeTarget] = useState(null);
    const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
    const [scheduledDowngrade, setScheduledDowngrade] = useState(null);
    const [isAdminGranted, setIsAdminGranted] = useState(false);
    const t = isFrench ? translations.fr : translations.en;

    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (!user) return;

        // Set up real-time listener for user's subscription status
        const unsubscribe = firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    const userData = doc.data();
                    setCurrentPlan(userData.accountType || 'free');
                    setSubscriptionEndDate(userData.subscriptionEndDate?.toDate());
                    setScheduledDowngrade(userData.subscriptionCancelAtPeriodEnd ? 'free' : null);
                    setIsAdminGranted(userData.isAdminGranted || false);
                }
            });

        return () => unsubscribe();
    }, []);

    const getPlanPrice = (plan, cycle) => {
        if (plan === 'free') return '0';
        if (plan === 'standard') {
            if (cycle === 'monthly') {
                return {
                    intro: '4.49',
                    regular: '5.99'
                };
            } else {
                return {
                    intro: '2.99',
                    regular: '3.99'
                };
            }
        }
        if (plan === 'pro') {
            if (cycle === 'monthly') {
                return {
                    intro: '12.99',
                    regular: '17.99'
                };
            } else {
                return {
                    intro: '8.99',
                    regular: '12.99'
                };
            }
        }
    };

    const handleDowngradeConfirm = async () => {
        setLoadingPlan('downgrade');
        setError(null);

        try {
            const user = firebase.auth().currentUser;
            if (!user) {
                throw new Error('Please sign in to downgrade your plan');
            }

            const idToken = await user.getIdToken();
            const response = await fetch(`${STRIPE_CLOUD_FUNCTION_BASE_URL}/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to downgrade subscription');
            }

            const { periodEnd } = await response.json();
            setSubscriptionEndDate(new Date(periodEnd * 1000));
            setScheduledDowngrade(downgradeTarget);
            setError({ severity: 'success', message: t.downgradeSuccessMessage });
        } catch (err) {
            console.error('Downgrade error:', err);
            setError({ severity: 'error', message: err.message || 'Failed to downgrade subscription' });
        } finally {
            setLoadingPlan(null);
            setDowngradeTarget(null);
        }
    };

    const handleUpgrade = async (plan) => {
        const isDowngrade = getPlanLevel(currentPlan) > getPlanLevel(plan);
        
        if (isDowngrade) {
            setDowngradeTarget(plan);
            return;
        }

        setLoadingPlan(plan);
        setError(null);

        try {
            const user = firebase.auth().currentUser;
            if (!user) {
                throw new Error('Please sign in to upgrade your plan');
            }

            const idToken = await user.getIdToken();
            const priceId = STRIPE_PRICE_IDS[plan][billingCycle];

            if (!priceId) {
                throw new Error('Invalid plan selection');
            }

            const response = await fetch(`${STRIPE_CLOUD_FUNCTION_BASE_URL}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    priceId,
                    successUrl: `${window.location.origin}/profile`,
                    cancelUrl: window.location.href
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create checkout session');
            }

            const { sessionId } = await response.json();
            
            // Redirect to Stripe Checkout
            const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
            const { error: stripeError } = await stripe.redirectToCheckout({ sessionId });
            
            if (stripeError) {
                throw stripeError;
            }
        } catch (err) {
            console.error('Subscription error:', err);
            setError(err.message || 'Failed to process subscription');
        } finally {
            setLoadingPlan(null);
        }
    };

    const handleManageSubscription = async () => {
        setLoadingManage(true);
        setError('');

        try {
            const user = firebase.auth().currentUser;
            if (!user) {
                throw new Error('Please sign in to manage your subscription');
            }

            const idToken = await user.getIdToken();
            const response = await fetch(`${STRIPE_CLOUD_FUNCTION_BASE_URL}/create-portal-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    locale: isFrench ? 'fr' : 'en'
                })
            });

            console.log('Portal session response status:', response.status);
            const responseText = await response.text();
            console.log('Portal session response text:', responseText);

            if (!response.ok) {
                throw new Error(responseText || 'Failed to access subscription management');
            }

            let data;
            try {
                data = JSON.parse(responseText);
            } catch (e) {
                console.error('Failed to parse response:', e);
                throw new Error('Invalid response from server');
            }

            if (!data.url) {
                throw new Error('No portal URL in response');
            }

            window.location.href = data.url; // Open in the same window
        } catch (err) {
            console.error('Portal session error:', err);
            setError(err.message || 'Failed to access subscription management');
        } finally {
            setLoadingManage(false);
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        return date.toLocaleDateString(isFrench ? 'fr-FR' : 'en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    const getPlanButtonColor = (plan, isCurrentPlan) => {
        if (plan === 'pro') return 'secondary';
        return 'primary';
    };

    const getButtonText = (plan, isCurrentPlan, hasActiveSubscription) => {
        if (isCurrentPlan) {
            return hasActiveSubscription ? t.manageSubscription : t.current;
        }
        return t.upgrade;
    };

    const getIsButtonDisabled = (plan, isCurrentPlan, hasActiveSubscription) => {
        return plan === 'pro' || (isCurrentPlan && !hasActiveSubscription);
    };

    const handlePlanAction = (plan, isCurrentPlan) => {
        if (isCurrentPlan && currentPlan !== 'free') {
            handleManageSubscription();
            return;
        }
        handleUpgrade(plan);
    };

    const renderPlanCard = (plan, title, features, icon) => {
        const isCurrentPlan = currentPlan === plan;
        const isLoading = loadingPlan === plan;
        const hasActiveSubscription = isCurrentPlan && currentPlan !== 'free';
        const isPro = plan === 'pro';
        const price = getPlanPrice(plan, billingCycle);
        const monthlyPrice = getPlanPrice(plan, 'monthly');
        const isAdminGrantedPlan = isCurrentPlan && isAdminGranted;

        const buttonColor = getPlanButtonColor(plan, isCurrentPlan);
        const buttonText = getButtonText(plan, isCurrentPlan, hasActiveSubscription);
        const isButtonDisabled = getIsButtonDisabled(plan, isCurrentPlan, hasActiveSubscription);
        const handleButtonClick = () => {
            if (isCurrentPlan && currentPlan !== 'free') {
                setLoadingManage(true);
                handleManageSubscription();
            } else {
                handleUpgrade(plan);
            }
        };

        // Hide button for VIP plans, current free plan, or free plan when on paid plan
        const shouldHideButton = isAdminGrantedPlan || 
            (isCurrentPlan && !hasActiveSubscription) || 
            (scheduledDowngrade && plan === scheduledDowngrade) ||
            (currentPlan !== 'free' && plan === 'free') ||
            isAdminGranted; // Hide all buttons if user has any VIP subscription

        // Show loading state for manage subscription button or upgrade button
        const showLoading = (isCurrentPlan && loadingManage) || isLoading;

        return (
            <PlanCard isCurrentPlan={isCurrentPlan} sx={{
                opacity: isPro ? 0.7 : 1,
                position: 'relative'
            }}>
                {isPro && (
                    <ComingSoonOverlay>
                        <Typography variant="h6" color="textSecondary">
                            {t.comingSoon}
                        </Typography>
                    </ComingSoonOverlay>
                )}
                <CardContent sx={{ 
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: {
                        xs: 'calc(100% - 56px)',
                        sm: 'auto'
                    },
                    filter: isPro ? 'grayscale(0.5)' : 'none'
                }}>
                    {isCurrentPlan && (
                        <Box sx={{ 
                            position: 'absolute', 
                            top: 8, 
                            right: 8, 
                            display: 'flex', 
                            gap: 1, 
                            alignItems: 'center'
                        }}>
                            <Chip
                                label={t.current}
                                color="primary"
                                size="small"
                            />
                        </Box>
                    )}
                    <Box display="flex" alignItems="center" mb={2}>
                        {icon}
                        <Typography variant="h5" component="h2" ml={1}>
                            {isAdminGrantedPlan ? (plan === 'pro' ? t.proGranted : t.standardGranted) : title}
                        </Typography>
                    </Box>
                    {isAdminGrantedPlan && (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            {t.grantedPlan}
                        </Typography>
                    )}
                    {isCurrentPlan && scheduledDowngrade && (
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            mt: 0,
                            mb: 0.5
                        }}>
                            <Chip
                                label={t.subscriptionCanceled}
                                color="error"
                                size="small"
                                variant="outlined"
                                sx={{
                                    borderWidth: '2px',
                                    fontWeight: 500
                                }}
                            />
                            <Typography variant="body2" color="textSecondary">
                                {t.endsOn}: {formatDate(subscriptionEndDate)}
                            </Typography>
                        </Box>
                    )}
                    {isCurrentPlan && !scheduledDowngrade && subscriptionEndDate && (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                            {isAdminGrantedPlan ? t.expiresOn : t.renewsOn}: {formatDate(subscriptionEndDate)}
                        </Typography>
                    )}
                    {plan !== 'pro' && plan !== 'free' && (
                        <Box sx={{ mb: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
                                <Chip
                                    label={t.introductoryPrice}
                                    color="success"
                                    size="small"
                                    sx={{ 
                                        height: '20px',
                                        '& .MuiChip-label': {
                                            fontSize: '0.7rem',
                                            padding: '0 8px'
                                        }
                                    }}
                                />
                                <Typography 
                                    variant="caption" 
                                    color="success.main"
                                    sx={{ 
                                        whiteSpace: 'nowrap',
                                        display: 'block'
                                    }}
                                >
                                    {t.lockInOffer}
                                </Typography>
                            </Box>
                            <Typography variant="h4" component="div" sx={{ mb: 0.5, color: billingCycle === 'yearly' ? 'success.main' : 'inherit' }}>
                                ${typeof price === 'object' ? price.intro : price}
                                <Typography 
                                    variant="subtitle1" 
                                    component="span" 
                                    color="text.secondary"
                                    sx={{ ml: 0.5 }}
                                >
                                    {billingCycle === 'yearly' ? t.yearSuffix : t.monthSuffix}
                                </Typography>
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="caption" color="text.secondary">
                                        {t.regularPrice}:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            textDecoration: 'line-through',
                                            color: 'error.main',
                                            fontSize: '0.9rem',
                                        }}
                                    >
                                        ${typeof price === 'object' ? price.regular : monthlyPrice}{t.monthSuffix}
                                    </Typography>
                                </Box>
                                {billingCycle === 'yearly' && (
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        color="text.secondary"
                                        sx={{ fontSize: '0.8rem' }}
                                    >
                                        ({t.billedAnnually})
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                    {(plan === 'free' || plan === 'pro') && (
                        <Box sx={{ mb: 2 }}>
                            {plan === 'pro' && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
                                    <Chip
                                        label={t.introductoryPrice}
                                        color="success"
                                        size="small"
                                        sx={{ 
                                            height: '20px',
                                            '& .MuiChip-label': {
                                                fontSize: '0.7rem',
                                                padding: '0 8px'
                                            }
                                        }}
                                    />
                                    <Typography 
                                        variant="caption" 
                                        color="success.main"
                                        sx={{ 
                                            whiteSpace: 'nowrap',
                                            display: 'block'
                                        }}
                                    >
                                        {t.lockInOffer}
                                    </Typography>
                                </Box>
                            )}
                            {plan === 'free' && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start', mb: 1 }}>
                                    <Chip
                                        label={t.alwaysFree}
                                        color="primary"
                                        size="small"
                                        sx={{ 
                                            height: '20px',
                                            '& .MuiChip-label': {
                                                fontSize: '0.7rem',
                                                padding: '0 8px'
                                            }
                                        }}
                                    />
                                </Box>
                            )}
                            <Typography variant="h4" component="div" sx={{ mb: 0.5, color: billingCycle === 'yearly' ? 'success.main' : 'inherit' }}>
                                ${typeof price === 'object' ? price.intro : (plan === 'free' ? '0' : price)}
                                <Typography 
                                    variant="subtitle1" 
                                    component="span" 
                                    color="text.secondary"
                                    sx={{ ml: 0.5 }}
                                >
                                    {billingCycle === 'yearly' ? t.yearSuffix : t.monthSuffix}
                                </Typography>
                            </Typography>
                            {plan === 'pro' && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography variant="caption" color="text.secondary">
                                            {t.regularPrice}:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="div"
                                            sx={{
                                                textDecoration: 'line-through',
                                                color: 'error.main',
                                                fontSize: '0.9rem',
                                            }}
                                        >
                                            ${typeof price === 'object' ? price.regular : monthlyPrice}{t.monthSuffix}
                                        </Typography>
                                    </Box>
                                    {billingCycle === 'yearly' && (
                                        <Typography
                                            variant="body2"
                                            component="div"
                                            color="text.secondary"
                                            sx={{ fontSize: '0.8rem' }}
                                        >
                                            ({t.billedAnnually})
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            {plan === 'free' && billingCycle === 'yearly' && (
                                <Typography
                                    variant="body2"
                                    component="div"
                                    color="text.secondary"
                                    sx={{ fontSize: '0.8rem' }}
                                >
                                    ({t.billedAnnually})
                                </Typography>
                            )}
                        </Box>
                    )}
                    <List dense sx={{ 
                        flex: 1,
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        maxHeight: {
                            xs: 'calc(100% - 160px)',
                            sm: 'none'
                        },
                        paddingBottom: {
                            xs: 2,
                            sm: 0
                        },
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            WebkitAppearance: 'none'
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '4px'
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555'
                        }
                    }}>
                        {features.map((feature, index) => {
                            const featureContent = typeof feature === 'string' && (feature.includes('<coffee>') || feature.includes('<strike>')) ? (
                                <span dangerouslySetInnerHTML={{
                                    __html: feature
                                        .replace(
                                            /<coffee>(.*?)<\/coffee>/,
                                            `<a href="https://www.buymeacoffee.com/zilongl" target="_blank" rel="noopener" style="text-decoration: underline;">$1</a>`
                                        )
                                        .replace(
                                            /<strike>(.*?)<\/strike>/,
                                            `<span style="text-decoration: line-through; color: #d32f2f;">$1</span>`
                                        )
                                }} />
                            ) : feature;

                            let icon;
                            if (index === 0) {
                                if (plan === 'pro') {
                                    icon = <StarIcon color="primary" fontSize="small" />;
                                } else if (plan === 'standard') {
                                    icon = <CheckIcon color="primary" fontSize="small" />;
                                } else {
                                    icon = <CheckIcon color="primary" fontSize="small" />;
                                }
                            } else {
                                if (plan === 'pro') {
                                    icon = <WorkspacePremiumIcon color="secondary" fontSize="small" />;
                                } else if (plan === 'standard') {
                                    icon = <StarIcon color="primary" fontSize="small" />;
                                } else {
                                    icon = <CheckIcon color="primary" fontSize="small" />;
                                }
                            }

                            return (
                                <ListItem key={index} disableGutters>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={featureContent} 
                                        sx={{
                                            '.MuiListItemText-primary': {
                                                fontWeight: (plan === 'standard' || plan === 'pro') && index > 0 ? 700 : 400
                                            }
                                        }}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                    {!shouldHideButton && (
                        <Button
                            variant={isCurrentPlan && !hasActiveSubscription ? "outlined" : "contained"}
                            color={buttonColor}
                            fullWidth
                            disabled={isButtonDisabled || showLoading}
                            onClick={handleButtonClick}
                            sx={{
                                minHeight: '36px',
                                position: 'relative'
                            }}
                        >
                            {showLoading ? (
                                <CircularProgress 
                                    size={24} 
                                    color="inherit" 
                                    sx={{ 
                                        position: 'absolute',
                                        left: '50%',
                                        marginLeft: '-12px'
                                    }} 
                                />
                            ) : buttonText}
                        </Button>
                    )}
                </CardActions>
            </PlanCard>
        );
    };

    return (
        <>
            <StyledModal
                open={open}
                onClose={onClose}
                aria-labelledby="subscription-modal-title"
            >
                <ModalContent>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                            zIndex: 1
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="center" 
                        mt={4}
                        sx={{
                            height: {
                                xs: 'calc(100% - 32px)',
                                sm: 'auto'
                            }
                        }}
                    >
                        <Typography id="subscription-modal-title" variant="h4" component="h2" gutterBottom>
                            {t.title}
                        </Typography>
                        
                        <ToggleButtonGroup
                            value={billingCycle}
                            exclusive
                            onChange={(e, newValue) => newValue && setBillingCycle(newValue)}
                            sx={{ mb: 4 }}
                        >
                            <ToggleButton 
                                value="yearly"
                                sx={{
                                    position: 'relative',
                                    paddingRight: '16px'
                                }}
                            >
                                {t.yearly}
                                <StyledBadge>{t.yearlySavings}</StyledBadge>
                            </ToggleButton>
                            <ToggleButton value="monthly">
                                {t.monthly}
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <PlansContainer>
                            {renderPlanCard('free', t.free, t.freePlan.features, 
                                <CheckIcon fontSize="large" />)}
                            {renderPlanCard('standard', t.standard, t.standardPlan.features, 
                                <StarIcon fontSize="large" color="primary" />)}
                            {renderPlanCard('pro', t.pro, t.proPlan.features, 
                                <WorkspacePremiumIcon fontSize="large" color="secondary" />)}
                        </PlansContainer>
                    </Box>
                </ModalContent>
            </StyledModal>
            
            <Snackbar 
                open={!!error} 
                autoHideDuration={6000} 
                onClose={() => setError(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setError(null)} 
                    severity={error?.severity || 'error'} 
                    sx={{ width: '100%' }}
                >
                    {error?.message}
                </Alert>
            </Snackbar>
        </>
    );
} 